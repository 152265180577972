import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Commission from "../generalsettings/Commission";
import CardRequirement from "../generalsettings/CardRequirement";
import Category from "./Category";
import Tag from "./Tag";
import Feature from "./Feature";

const GeneralSettings = () => {
  const [tabNum, setTabNum] = useState(1);
  // const tabList = ["Commission", "Card Settings"];
  const tabList = ["Card Settings", "Category", "Tag", "Featured"];

  const SideMenu = ({ title, index }) => {
    return (
      <button
        className={`text-start nav-link ${tabNum === index && "active"}`}
        id={`v-pills-${title.toLowerCase()}-tab`}
        data-bs-toggle="pill"
        data-bs-target={`#v-pills-${title.toLowerCase()}`}
        type="button"
        role="tab"
        aria-controls={`v-pills-${title.toLowerCase()}`}
        aria-selected="true"
        onClick={() => setTabNum(index)}
      >
        {title}
      </button>
    );
  };

  // const contentList = [<Commission />,<CardRequirement />];
  const contentList = [<CardRequirement />, <Category />, <Tag />, <Feature />];

  const MenuContent = ({ index }) => {
    return contentList[index - 1];
  };

  return (
    <>
      <Helmet>
        <title>General Settings | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-12">
            <div className="bg-light shadow p-3 mb-3 bg-body rounded ">
              {/* Content of the first div */}
              <div className="d-flex align-items-start">
                <div
                  className="nav flex nav-pills me-3 "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {tabList?.map((item, index) => (
                    <SideMenu title={item} key={item} index={index + 1} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="bg-light shadow p-3 mb-5 bg-body rounded h-100">
              {/* Content of the second div */}
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <MenuContent index={tabNum} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GeneralSettings;
