import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useGetTransactionList = (initialQueryParams) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [transactionList, setTransactionList] = useState([]);
  const [transactionListMeta, setTransactionListMeta] = useState({});
  const [transactionHistory, setTransactionHistory] = useState({});
  const [transactionListError, setTransactionListError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const navigate = useNavigate();

  const fetchTransactionList = useQuery({
    queryKey: ["transactionListUserAdvertiser", initialQueryParams], // Include queryParams in the query key
    queryFn: async () => {
      // Modify queryFn to accept queryParams
      try {
        setIsLoading(true);
        let url = constructtransactionListUrl(initialQueryParams);
        //console.log("API URL:", url); // Log the API URL
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text(); // Get response text for error handling
          setTransactionListError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch data"); // Throw an error to trigger onError callback
        }

        const responseData = await response.json();



        setTransactionList(responseData.data); 
        setTransactionListMeta(responseData.meta); 
        setTransactionHistory(responseData.transactionHistory);
        setTotalRows(responseData.meta.pagination.total_count);

        setIsLoading(false);

        return responseData; 
      } catch (error) {
        
        setTransactionListError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {

      setTransactionListError(error);
      setIsLoading(false);

      throw error;
    },
  });

  const fetchTransactionListWithParams = (queryParams) => {

    const apiUrl = constructtransactionListUrl(queryParams);

    fetchTransactionList.refetch(apiUrl);
  };

  const constructtransactionListUrl = (queryParams) => {
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");
    return `${process.env.REACT_APP_API_BASE_URL}/api/transaction/list?${queryString}`;
  };

  useEffect(() => {
    setTotalRows(transactionListMeta?.pagination?.total_count);
  }, [transactionListMeta]);

  return {
    fetchTransactionListWithParams,
    isLoadingTransactionList: isLoading,
    setIsLoadingTransactionList: setIsLoading,
    isErrorTransactionList: fetchTransactionList.isError,
    transactionList,
    setTransactionList,
    transactionListMeta,
    transactionHistory,
    transactionListError,
    totalRows,
    setTotalRows,
  };
};

export default useGetTransactionList;
