const influencerPrefix = "influencer"; // Prefix

// Remove the prefix from routes since it will be added dynamically
const influencerRoutes = {
  DASHBOARD: "/dashboard",
  AD_SERVICE: "/ad-service",
  PACKAGES: "/packages",
  CREATE_PACKAGE: "/packages/create-package",
  UPDATE_PACKAGE: "/packages/update-package",
  SOCIAL_PROFILES: "/social-profiles",
  CREATE_SOCIAL_PROFILE: "/social-profiles/create-social-profile",
  UPDATE_SOCIAL_PROFILE: "/social-profiles/update-social-profile",
  CALENDER: "/calender",
  EARNINGS_OVERVIEW: "/earnings-overview",
  FEATURE_REQUEST: "/feature-request",
  INFLUENCER_SETTINGS: "/influencer-settings",
  CARD_DETAILS: "/influencer-settings/card-details",
  ORDERS: "/orders",
  ORDERS_DETAILS: "/orders/details",
  MESSAGE: "/message",
  FEATURE: "/feature",
  TRANSACTION: "/transaction",
};

// Page names remain the same
const influencerPageNames = {
  DASHBOARD: "Influencer Dashboard",
  AD_SERVICE: "Ad Service",
  PACKAGES: "Packages",
  CREATE_PACKAGE: "Create Package",
  UPDATE_PACKAGE: "Update Package",
  SOCIAL_PROFILES: "Social Profiles",
  CREATE_SOCIAL_PROFILE: "Create Social Profile",
  UPDATE_SOCIAL_PROFILE: "Update Social Profile",
  CALENDER: "Calender",
  EARNINGS_OVERVIEW: "Earnings Overview",
  FEATURE_REQUEST: "Feature Request",
  INFLUENCER_SETTINGS: "Influencer Settings",
  CARD_DETAILS: "Card Details",
  ORDERS: "Orders",
  ORDERS_DETAILS: "Order Details",
  MESSAGE: "Message",
  FEATURE: "Featured",
  TRANSACTION: "Transaction",
};

// Dynamically add the prefix to the routes
const influencerPrefixedRoutes = {};

// Add the prefix to each route
for (const key in influencerRoutes) {
  if (influencerRoutes.hasOwnProperty(key)) {
    influencerPrefixedRoutes[
      key
    ] = `/${influencerPrefix}${influencerRoutes[key]}`;
  }
}

export default influencerPrefixedRoutes;

export {
  influencerPrefix,
  influencerRoutes,
  influencerPrefixedRoutes,
  influencerPageNames,
};
