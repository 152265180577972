/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import useClinicDetails from "../../../../hooks/data-hooks/admin/card-management/useClinicDetails";
import { Link, useParams } from "react-router-dom";
import MyImage from "../../../../custom-components/MyImage";
import ProfileBarShimmer from "../../../common/components/shimmers/ProfileBarShimmer";
import BackButton from "../../../../helpers/backButton";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import useClinicListLogic from "../../../../hooks/data-hooks/admin/card-management/useClinicListLogic";
import useGetTransactionSummary from "../../../../hooks/data-hooks/admin/card-management/useGetTransactionSummary";
import { motion } from "framer-motion";
import {
  FaUserAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import {
  BsCashStack,
  BsCurrencyDollar,
  BsShieldCheck,
  BsCalendar,
} from "react-icons/bs";
import { MdOutlineAttachMoney, MdEmail } from "react-icons/md";
import { AiOutlineBank, AiOutlineGlobal } from "react-icons/ai";

import Log from "./Log";
import Loader from "../../../common/components/loader/Loader";

const CardManagementDetails = () => {
  let { id, influencerId } = useParams();

  const {
    fetchClinicDetailsWithId,
    isLoading: isLoading1,
    isError,
    clinicDetails,
  } = useClinicDetails(id);
  const { fetchDataWithId, isLoadingDetails, details } =
    useGetTransactionSummary(influencerId);
  useEffect(() => {
    fetchDataWithId(influencerId);
  }, [influencerId]);
  useEffect(() => {
    fetchClinicDetailsWithId(id); // Fetch clinic details when clinicId changes
  }, [id]);
  const { clinicStates, handleVerifyClinic, handleDeclineCard } =
    useClinicListLogic();
  const address = `${clinicDetails?.cardholder?.line1}, ${clinicDetails?.cardholder?.line2}, ${clinicDetails?.cardholder?.city}, ${clinicDetails?.cardholder?.state}, ${clinicDetails?.cardholder?.country}, ${clinicDetails?.cardholder?.postal_code}`;
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  if (isLoading1 || isError || isLoadingDetails) {
    return <Loader />;
  }

  return (
    <div className="container py-4">
      <BackButton />
      <div className="d-flex align-items-center mb-4">
        <h3 className="fw-bold mb-0">Card Holder Details</h3>
      </div>

      <div className="application-details">
        {(isLoading1 || isError || isLoadingDetails) && <ProfileBarShimmer />}
        {!isLoading1 && !isError && clinicDetails && !isLoadingDetails && (
          <motion.div
            className="applicant-section"
            initial="hidden"
            whileInView="visible"
            variants={fadeInUp}
            viewport={{ once: true, amount: 0.2 }}
          >
            <div className="applicant-info">
              <Link
                to={`${adminPrefixedRoutes.INFLUENCER_DETAILS}/${clinicDetails?.cardholder?.influencerId?._id}`}
              >
                <MyImage
                  src={
                    clinicDetails?.cardholder?.influencerId?.profileImageUrl ??
                    ""
                  }
                  addBaseUrl={false}
                  alt=""
                  className="applicant-image"
                  style={{ width: "130px", height: "130px" }}
                />
              </Link>
              <div className="applicant-details">
                <Link
                  className="text-decoration-none"
                  to={`${adminPrefixedRoutes.INFLUENCER_DETAILS}/${clinicDetails?.cardholder?.influencerId?._id}`}
                >
                  <h2 className="applicant-name">
                    <FaUserAlt className="me-2 text-primary" size={16} />
                    {clinicDetails?.cardholder?.influencerId?.fullName ||
                      "Name unavailable"}
                  </h2>
                </Link>
                <p className="applicant-email">
                  <FaEnvelope className="me-2 text-success" />
                  {clinicDetails?.cardholder?.influencerId?.email ||
                    "Email unavailable"}
                </p>
                <p className="applicant-phone">
                  <FaPhoneAlt className="me-2 text-info" />
                  {clinicDetails?.cardholder?.phone_number ||
                    "Phone unavailable"}
                </p>
                <p className="applicant-phone">
                  <FaMapMarkerAlt className="me-2 text-warning" />
                  {address || "Address unavailable"}
                </p>
              </div>
            </div>
            <div className="action-buttons">
              <button
                onClick={() =>
                  handleVerifyClinic(
                    clinicDetails?.cardholder?.influencerId?._id
                  )
                }
                disabled={
                  clinicStates[clinicDetails?.cardholder?.influencerId?._id]
                    ?.verifyClinicLoading ||
                  clinicDetails?.cardholder?.status === "active"
                }
                className="btn-active me-2"
              >
                {clinicStates[clinicDetails?.cardholder?.influencerId?._id]
                  ?.verifyClinicLoading
                  ? "Approving..."
                  : "Approve"}
              </button>
              <button
                onClick={() =>
                  handleDeclineCard(
                    clinicDetails?.cardholder?.influencerId?._id
                  )
                }
                disabled={
                  clinicStates[clinicDetails?.cardholder?.influencerId?._id]
                    ?.declineCardLoading ||
                  clinicDetails?.cardholder?.status !== "pending"
                }
                className="btn-inactive"
              >
                {clinicStates[clinicDetails?.cardholder?.influencerId?._id]
                  ?.declineCardLoading
                  ? "Declining..."
                  : "Decline"}
              </button>
            </div>

            <motion.div
              className="account-info-section"
              initial="hidden"
              whileInView="visible"
              variants={fadeInUp}
              viewport={{ once: true, amount: 0.2 }}
            >
              <h3>
                <BsShieldCheck className="me-2 mb-2 text-secondary" />
                Connected Stripe Account Information
              </h3>
              <hr />
              <div className="account-info">
                <div className="info-item">
                  <span>
                    <AiOutlineBank className="me-2 text-primary" />
                    Stripe Account:
                  </span>
                  <p className="account-no">
                    {clinicDetails?.cardholder?.connectAccount
                      ?.stripeAccountNumber || "Info unavailable"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <MdEmail className="me-2 text-success" />
                    Email:
                  </span>
                  <p className="account-email">
                    {clinicDetails?.cardholder?.connectAccount?.stripeEmail ||
                      "Info unavailable"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <AiOutlineGlobal className="me-2 text-info" />
                    Country:
                  </span>
                  <p>
                    {clinicDetails?.cardholder?.connectAccount?.countryCode ||
                      "Info unavailable"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <BsCurrencyDollar className="me-2 text-warning" />
                    Currency:
                  </span>
                  <p>
                    {clinicDetails?.cardholder?.connectAccount
                      ?.defaultCurrency || "Info unavailable"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <BsShieldCheck className="me-2 text-secondary" />
                    Verification Status:
                  </span>
                  <p>
                    {clinicDetails?.cardholder?.connectAccount?.stripeVerify
                      ? "Verified"
                      : "Unverified"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <BsCashStack className="me-2 text-primary" />
                    Transfer Status:
                  </span>
                  <p>
                    {clinicDetails?.cardholder?.connectAccount?.transfers ||
                      "Info unavailable"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <MdOutlineAttachMoney className="me-2 text-success" />
                    Payment Status:
                  </span>
                  <p>
                    {clinicDetails?.cardholder?.connectAccount?.cardPayments ||
                      "Info unavailable"}
                  </p>
                </div>
                <div className="info-item">
                  <span>
                    <BsCalendar className="me-2 text-warning" />
                    Created Date:
                  </span>
                  <p>
                    {getFormattedDate(
                      clinicDetails?.cardholder?.connectAccount?.createdAt
                    ) || "Info unavailable"}
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="earnings-section"
              initial="hidden"
              whileInView="visible"
              variants={fadeInUp}
              viewport={{ once: true, amount: 0.2 }}
            >
              <h3>
                <MdOutlineAttachMoney className="me-2 mb-2 text-success" />
                Earings And Revenue
              </h3>
              <hr />
              <div className="earnings-info">
                <div className="earnings-item">
                  <span>
                    <BsCashStack className="me-2 text-info" />
                    Total Earnings By Influencer:
                  </span>
                  <p>{details?.data?.totalInfluencerAmount}</p>
                </div>
                <div className="earnings-item">
                  <span>
                    <BsCurrencyDollar className="me-2 text-warning" />
                    Total Revenue By The Platform:
                  </span>
                  <p>{details?.data?.totalAmount - details?.data?.totalInfluencerAmount}</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>

      <Log id={influencerId} />
    </div>
  );
};

export default CardManagementDetails;
