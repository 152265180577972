// src/modules/admin/components/pages/Log.js
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import adminPrefixedRoutes, {
  adminPageNames,
} from "../../../../routes/all-routes/adminRoutes";
import { Link } from "react-router-dom";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";

const fetchLogs = async ({
  id,
  token,
  page,
  pageSize,
  filters,
  setTotalRows,
}) => {
  const { status, timeRange, startDate, endDate } = filters;

  const params = new URLSearchParams({
    userId: id,
    pageSize,
    pageNumber: page,
    ...(status && { status }),
    ...(timeRange && timeRange !== "custom" && { timeRange }),
    ...(timeRange === "custom" &&
      startDate && { startDate: startDate.toISOString().split("T")[0] }),
    ...(timeRange === "custom" &&
      endDate && { endDate: endDate.toISOString().split("T")[0] }),
  });

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/log/all?${params}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch logs");
    }

    const data = await response.json();

    setTotalRows(data.meta.total_count);
    return data;
  } catch (error) {
    console.error("Error fetching logs:", error);
    throw error;
  }
};

const Log = ({ id }) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [filters, setFilters] = useState({
    status: "",
    timeRange: "",
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // Ensure endDate is at least 1 day ahead of startDate initially
  });

  const { data, isLoading } = useQuery({
    queryKey: ["logs", id, currentPage, perPage, filters],
    queryFn: () =>
      fetchLogs({
        id,
        token,
        page: currentPage,
        pageSize: perPage,
        filters,
        setTotalRows,
      }),
    keepPreviousData: true,
   
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev, [key]: value };
      // Ensure endDate is at least 1 day later than startDate
      if (key === "startDate") {
        const newEndDate = new Date(value);
        newEndDate.setDate(newEndDate.getDate() + 1);
        if (newEndDate > prev.endDate) {
          updatedFilters.endDate = newEndDate; // Adjust endDate to be 1 day after the new startDate
        }
      }
      if (key === "endDate") {
        const minEndDate = new Date(prev.startDate);
        minEndDate.setDate(minEndDate.getDate() + 1);
        if (value < minEndDate) {
          updatedFilters.endDate = minEndDate; // Adjust endDate to be at least 1 day after startDate
        }
      }
      return updatedFilters;
    });
    setCurrentPage(1); // Reset to first page on filter change
  };

  const columns = [
    {
      name: "Index",
      cell: (row, rowIndex) => (currentPage - 1) * perPage + rowIndex + 1,
      width: "80px", // Optional: adjust width for the index column
    },
    {
      name: "Log ID",
      selector: (row) => (
        <Link
          className="link-style"
          to={`${adminPrefixedRoutes.LOG_DETAILS}/${row._id}`}
        >
          {row._id}
        </Link>
      ),
      sortable: true,
    },
    { name: "Request", selector: (row) => row.request, sortable: true },
    { name: "Status", selector: (row) => row.status || "-", sortable: true },

    {
      name: "Date & Time",
      selector: (row) => getFormattedDate(row.createdAt),
      sortable: true,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {adminPageNames.LOG} | Admin | {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className=" my-4">
        <h2 className="mb-4 text-title">Card Log Viewer</h2>

        {/* Filter Section */}
        <div className="d-flex flex-wrap gap-3 mb-3 align-items-end">
          <div>
            <label className="form-label">Status</label>
            <select
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              <option value="SUCCESS">Success</option>
              <option value="ERROR">Error</option>
            </select>
          </div>
          <div>
            <label className="form-label">Time Range</label>
            <select
              value={filters.timeRange}
              onChange={(e) => handleFilterChange("timeRange", e.target.value)}
              className="form-select"
            >
              <option value="">All Time</option>
              <option value="last24hours">Last 24 Hours</option>
              <option value="lastWeek">Last 7 Days</option>
              <option value="lastMonth">Last Month</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          {filters.timeRange === "custom" && (
            <div>
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={filters.startDate}
                onChange={(date) => handleFilterChange("startDate", date)}
                className="form-control"
              />
            </div>
          )}
          {filters.timeRange === "custom" && (
            <div>
              <label className="form-label">End Date</label>
              <DatePicker
                selected={filters.endDate}
                onChange={(date) => handleFilterChange("endDate", date)}
                className="form-control"
              />
            </div>
          )}
        </div>

        {/* DataTable Component */}
        <DataTable
          columns={columns}
          data={data?.data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationDefaultPage={currentPage}
          onChangePage={(page) => {
            setCurrentPage(page);
          }}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPerPage(newPerPage);
            setCurrentPage(page);
          }}
          highlightOnHover
        />
      </div>
    </>
  );
};

export default Log;
