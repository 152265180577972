import React, { useEffect } from "react";
import useGetTransactionDetailsById from "../../../../hooks/data-hooks/admin/transaction/useGetTransactionDetailsById";
import { useParams } from "react-router";
import Loader from "../../../common/components/loader/Loader";
import BackButton from "../../../../helpers/backButton";
import {
  FaDollarSign,
  FaUserTie,
  FaUserAlt,
  FaCalendarAlt,
  FaHourglassHalf,
  FaCoins,
  FaMoneyBillWave,
  FaRegCreditCard,
} from "react-icons/fa";

const TransactionDetails = () => {
  const params = useParams();
  const { fetchTransactionDetailWithId, isLoading, transactionDetails } =
    useGetTransactionDetailsById(params.id);

  useEffect(() => {
    fetchTransactionDetailWithId(params.id); // Fetch transaction details when transaction-id/param-id changes
  }, [params.id]);

  // const shippingAddress = JSON.parse(
  //   transactionDetails?.orderId?.shippingAddress || "{}"
  // );
  // const totalDetails = JSON.parse(transactionDetails?.totalDetails || "{}");

  const date = new Date(transactionDetails?.createdAt);
  const formattedDate = date.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });



  if (isLoading) return <Loader />;
  return (
    <div className="container ">
      <BackButton />
      {transactionDetails && (
        <div className="row g-4 fs-18 align-items-start">
          <div className="col-lg-6">
            <div className="border border-1 rounded-3  shadow bg-white">
              <div className="mb-4 bg-primary-color p-3 d-flex    justify-content-between flex-wrap  rounded-top text-white">
                <h6 className="me-2  fw-bold ">Transaction ID</h6>
                <p className=" m-0 text-wrap  ">{transactionDetails?._id}</p>
              </div>
              <div className="p-3 pt-0 ">
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaUserTie className="me-2" />
                    Influencer Name
                  </h6>
                  <span className="">
                    {transactionDetails?.orderId?.influencerId?.fullName ??
                      "Info Unvailable"}
                  </span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaUserAlt className="me-2" />
                    Advertiser Name
                  </h6>
                  <p>{transactionDetails?.orderId?.advertiserId?.fullName}</p>
                </div>

                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaDollarSign className="me-2" />
                    Payment Method
                  </h6>
                  <span>{transactionDetails?.paymentMethod}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaHourglassHalf className="me-2" />
                    Order Status
                  </h6>
                  <span>{transactionDetails?.orderId?.orderStatus}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaCoins className="me-2" />
                    Payment Status
                  </h6>
                  <span>{transactionDetails?.orderId?.paymentStatus}</span>
                </div>
                {/* <div className="mb-4 d-flex  justify-content-between   ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    Shipping Address
                  </h6>
                  <div className="text-end  ">
                    <p className="m-0 ">
                      <span className="">Name :</span> {shippingAddress.name}
                    </p>
                    <p className="m-0 ">
                      <span className="">Phone Number :</span>{" "}
                      {shippingAddress.phoneNumber}
                    </p>
                    <p className="m-0 ">
                      <span className="">Address :</span>{" "}
                      {shippingAddress.address}
                    </p>
                  </div>
                </div> */}

                <div className="mb-2 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaCalendarAlt className="me-2" />
                    Order Date
                  </h6>
                  <span>{formattedDate}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="border border-1 rounded-3 shadow bg-white ">
              <div className="p-3">
                {/* <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    Delivery Status
                  </h6>
                  <span>{transactionDetails?.orderId?.deliveryStatus}</span>
                </div> */}
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaMoneyBillWave className="me-2" />
                    Advertiser Amount
                  </h6>
                  <span>{transactionDetails?.advertiserTotalAmount}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaMoneyBillWave className="me-2" />
                    Influencer Amount
                  </h6>
                  <span>{transactionDetails?.influencerTotalAmount}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaMoneyBillWave className="me-2" />
                    Transaction Amount
                  </h6>
                  <span>{transactionDetails?.amount}</span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaRegCreditCard className="me-2" />
                    Platform Charge for Advertiser
                  </h6>
                  <span>
                    {transactionDetails?.advertiserTotalAmount -
                      transactionDetails?.amount}
                  </span>
                </div>
                <div className="mb-4 d-flex  justify-content-between flex-wrap  ">
                  <h6 className="me-2 color-title-color fw-bold ">
                    <FaRegCreditCard className="me-2" />
                    Platform Charge for Influencer
                  </h6>
                  <span>
                    {transactionDetails?.amount -
                      transactionDetails?.influencerTotalAmount}
                  </span>
                </div>
              </div>
              <div className="p-3 d-flex  justify-content-between flex-wrap bg-primary-color rounded-bottom text-white">
                <h6 className="me-2  fw-bold ">Total Platform Charge</h6>
                <span>
                  {transactionDetails?.advertiserTotalAmount -
                    transactionDetails?.amount +
                    transactionDetails?.amount -
                    transactionDetails?.influencerTotalAmount}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TransactionDetails;
