import React, { useEffect, useState } from "react";
import useClinicDetails from "../../../../hooks/data-hooks/admin/card-list/useClinicDetails";
import { Link, useParams } from "react-router-dom";
import MyImage from "../../../../custom-components/MyImage";
import ProfileBarShimmer from "../../../common/components/shimmers/ProfileBarShimmer";
import BackButton from "../../../../helpers/backButton";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import adminPrefixedRoutes from "../../../../routes/all-routes/adminRoutes";
import useClinicListLogic from "../../../../hooks/data-hooks/admin/card-list/useClinicListLogic";
import CardTransactions from "../card/CardTransactions";

const CardDetails = () => {
  let { id } = useParams();
  const [cardId, setCardId] = useState(null);
  const { fetchClinicDetailsWithId, isLoading, isError, clinicDetails } =
    useClinicDetails(id);
  

  useEffect(() => {
    fetchClinicDetailsWithId(id); // Fetch clinic details when clinicId changes
  }, [id]);

  useEffect(() => {
    if (clinicDetails) {
      setCardId(clinicDetails?.data?.cardId);
    }
  }, [clinicDetails]);

  const { clinicStates, handleVerifyClinic, handleDeclineCard } =
    useClinicListLogic();

  return (
    <div className="container py-4">
      <BackButton />
      <div className="d-flex align-items-center mb-4">
        <h5 className="fw-bold mb-0">Card Details</h5>
      </div>
      <div className="card shadow-sm p-4">
        {(isLoading || isError) && <ProfileBarShimmer />}
        {!isLoading && !isError && clinicDetails && (
          <>
            <div className="d-flex justify-content-center mb-4">
              <Link
                to={`${adminPrefixedRoutes.INFLUENCER_DETAILS}/${clinicDetails?.data?.influencerId?._id}`}
              >
                <MyImage
                  src={clinicDetails?.data?.influencerId?.profileImageUrl ?? ""}
                  addBaseUrl={false}
                  alt=""
                  className="rounded-circle border border-2 border-secondary mb-3"
                  style={{ width: "130px", height: "130px" }}
                />
              </Link>
            </div>
            <div className="text-center">
              <h6 className="fw-bold">
                {clinicDetails?.data?.influencerId?.fullName ||
                  "Name unavailable"}
              </h6>

              <p className="text-muted mb-1">
                {clinicDetails?.data?.connectAccount?.stripeEmail ||
                  "Email unavailable"}
              </p>
            </div>
            <div className="my-4 text-center">
              <button
                onClick={() =>
                  handleVerifyClinic(clinicDetails?.data?.influencerId?._id)
                }
                disabled={
                  clinicStates[clinicDetails?.data?.influencerId?._id]
                    ?.verifyClinicLoading ||
                  clinicDetails?.data?.status === "active"
                }
                className="btn-active"
              >
                {clinicStates[clinicDetails?.cardholder?.influencerId?._id]
                  ?.verifyClinicLoading
                  ? "Approving..."
                  : "Approve"}
              </button>
            </div>

            <div className="mt-4">
              <h6 className="fw-bold text-primary">Card Info</h6>
              <hr />
              <div className="row">
                <div className="col-md-6 mb-3">
                  <p>
                    <strong>Card Type:</strong>{" "}
                    {clinicDetails?.data?.type || "Info unavailable"}
                  </p>
                  <p>
                    <strong>Status:</strong>{" "}
                    {clinicDetails?.data?.status || "Info unavailable"}
                  </p>
                  <p>
                    <strong>Country:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.countryCode ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Currency:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.defaultCurrency ||
                      "Info unavailable"}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Stripe Verified:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.stripeVerify
                      ? "Verified"
                      : "Unverified"}
                  </p>
                  <p>
                    <strong>Transfers:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.transfers ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Card Payments:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.cardPayments ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Created:</strong>{" "}
                    {getFormattedDate(
                      clinicDetails?.data?.connectAccount?.createdAt
                    ) || "Info unavailable"}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <h6 className="fw-bold text-primary">Spending Limits :</h6>
              {clinicDetails?.data?.spending_limits.map((limit, index) => (
                <div key={index} className=" mb-3">
                  <p>
                    <strong>Interval:</strong> {limit?.interval}
                  </p>
                  <p>
                    <strong>Limit:</strong> {limit?.amount}
                  </p>

                  <hr />
                </div>
              ))}
            </div>
            <div className="mt-4">
              <h6 className="fw-bold text-primary">
                Connected Stripe Account Info
              </h6>
              <hr />
              <div className="row">
                <div className="col-md-6 mb-3">
                  <p>
                    <strong>Stripe Account:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.stripeAccountNumber ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.stripeEmail ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Country:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.countryCode ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Currency:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.defaultCurrency ||
                      "Info unavailable"}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Stripe Verified:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.stripeVerify
                      ? "Verified"
                      : "Unverified"}
                  </p>
                  <p>
                    <strong>Transfers:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.transfers ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Card Payments:</strong>{" "}
                    {clinicDetails?.data?.connectAccount?.cardPayments ||
                      "Info unavailable"}
                  </p>
                  <p>
                    <strong>Created:</strong>{" "}
                    {getFormattedDate(
                      clinicDetails?.data?.connectAccount?.createdAt
                    ) || "Info unavailable"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <CardTransactions cardId={cardId} />
    </div>
  );
};

export default CardDetails;
