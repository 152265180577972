// src/modules/influencer/components/cms/CatdTransactions.js
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";

const fetchData = async ({
  cardId,
  token,
  page,
  pageSize,
  filters,
  setTotalRows,
}) => {
  const {
    transactionType,
    status,
    sortDirection,
    timeRange,
    startDate,
    endDate,
  } = filters;

  const params = new URLSearchParams({
    cardId,
    pageSize,
    pageNumber: page,
    ...(transactionType && { transactionType }),
    ...(status && { status }),
    ...(sortDirection && { sortDirection }),
    ...(timeRange && timeRange !== "custom" && { timeRange }),
    ...(timeRange === "custom" &&
      startDate && { startDate: startDate.toISOString().split("T")[0] }),
    ...(timeRange === "custom" &&
      endDate && { endDate: endDate.toISOString().split("T")[0] }),
  });

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/admin/top-up/all-transaction?${params}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch transactions");
    }

    const data = await response.json();

    setTotalRows(data.meta.total_count);
    return data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

const CardTransactions = ({cardId}) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [filters, setFilters] = useState({
    transactionType: "",
    status: "",
    sortDirection: "",
    timeRange: "",
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // Ensure endDate is at least 1 day ahead of startDate initially
  });

  const { data, isLoading } = useQuery({
    queryKey: ["logs", cardId, token, currentPage, perPage, filters],
    queryFn: () =>
      fetchData({
        cardId,
        token,
        page: currentPage,
        pageSize: perPage,
        filters,
        setTotalRows,
      }),
    keepPreviousData: true,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev, [key]: value };
      // Ensure endDate is at least 1 day later than startDate
      if (key === "startDate") {
        const newEndDate = new Date(value);
        newEndDate.setDate(newEndDate.getDate() + 1);
        if (newEndDate > prev.endDate) {
          updatedFilters.endDate = newEndDate; // Adjust endDate to be 1 day after the new startDate
        }
      }
      if (key === "endDate") {
        const minEndDate = new Date(prev.startDate);
        minEndDate.setDate(minEndDate.getDate() + 1);
        if (value < minEndDate) {
          updatedFilters.endDate = minEndDate; // Adjust endDate to be at least 1 day after startDate
        }
      }
      return updatedFilters;
    });
    setCurrentPage(1); // Reset to first page on filter change
  };

  const columns = [
    {
      name: "Index",
      cell: (row, rowIndex) => (currentPage - 1) * perPage + rowIndex + 1,
      width: "80px", // Optional: adjust width for the index column
    },
    {
      name: "Transaction ID",
      selector: (row) => row._id,
      //     (
      //     <Link
      //       className="link-style"
      //       to={`${adminPrefixedRoutes.LOG_DETAILS}/${row._id}`}
      //     >
      //       {row._id}
      //     </Link>
      //   ),
      sortable: true,
      width: "230px",
    },
    {
      name: "Type",
      selector: (row) => row.transactionType || "-",
      sortable: true,
    },
    { name: "Amount", selector: (row) => row.amount || "-", sortable: true },
    {
      name: "Balance Before",
      selector: (row) => row.balanceBefore || "-",
      sortable: true,
    },
    {
      name: "Balance After",
      selector: (row) => row.balanceAfter || "-",
      sortable: true,
    },
    { name: "Status", selector: (row) => row.status || "-", sortable: true },

    {
      name: "Date & Time",
      selector: (row) => getFormattedDate(row.createdAt),
      sortable: true,
      width: "180px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px", // override the row height
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f4f4f4",
        fontWeight: "bold",
        textTransform: "uppercase",
        // color: "#fff",
      },
    },
    cells: {
      style: {
        padding: "8px",
        textTransform: "capitalize",
      },
    },
  };

  return (
    <div className="bg-white p-4 my-4 rounded-3 shadow">
      <div className="d-flex flex-wrap  justify-content-between ">
        <h5 className="mb-4 fw-semibold me-4">Card Transactions</h5>

        {/* Filter Section */}
        <div className="d-flex flex-wrap gap-3 mb-3 align-items-end">
          <div>
            <label className="form-label">Type</label>
            <select
              value={filters.transactionType}
              onChange={(e) =>
                handleFilterChange("transactionType", e.target.value)
              }
              className="form-select"
            >
              <option value="">All</option>
              <option value="top-up">Top Up</option>
              <option value="withdrawal">Withdrawal</option>
            </select>
          </div>
          <div>
            <label className="form-label">Status</label>
            <select
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          {/* <div>
            <label className="form-label">Sort</label>
            <select
              value={filters.sortDirection}
              onChange={(e) =>
                handleFilterChange("sortDirection", e.target.value)
              }
              className="form-select"
            >
              <option value="">All</option>
              <option value="asc"> Accending</option>
              <option value="desc"> Descending</option>
            </select>
          </div> */}
          {/* <div>
            <label className="form-label">Time Range</label>
            <select
              value={filters.timeRange}
              onChange={(e) => handleFilterChange("timeRange", e.target.value)}
              className="form-select"
            >
              <option value="">All Time</option>
              <option value="last24hours">Last 24 Hours</option>
              <option value="lastWeek">Last 7 Days</option>
              <option value="lastMonth">Last Month</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          {filters.timeRange === "custom" && (
            <div>
              <label className="form-label">Start Date</label>
              <DatePicker
                selected={filters.startDate}
                onChange={(date) => handleFilterChange("startDate", date)}
                className="form-control"
              />
            </div>
          )}
          {filters.timeRange === "custom" && (
            <div>
              <label className="form-label">End Date</label>
              <DatePicker
                selected={filters.endDate}
                onChange={(date) => handleFilterChange("endDate", date)}
                className="form-control"
              />
            </div>
          )} */}
        </div>
      </div>

      {/* DataTable Component */}
      <div className="mt-3   ">
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={data?.data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationDefaultPage={currentPage}
          onChangePage={(page) => {
            setCurrentPage(page);
          }}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPerPage(newPerPage);
            setCurrentPage(page);
          }}
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default CardTransactions;
