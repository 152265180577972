// src/modules/influencer/components/cms/CardDetailsMobile.js
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loader from "../../../common/components/loader/Loader";
import logo from "../../../../assets/image/logo-white.png";
import { FaLock } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";

const CardDetailsMobile = () => {
  const { userId, cardIdMob } = useParams();
  console.log(userId, cardIdMob);

  const [isGeneratingKey, setIsGeneratingKey] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [nonce, setNonce] = useState(null);
  const [elements, setElements] = useState(null);
  const [ephemeralKeySecret, setEphemeralKeySecret] = useState(null);
  const theme = "dark"; // 'default' or 'dark'
  const [isRevealed, setIsRevealed] = useState(false);
  const [countdown, setCountdown] = useState(0); // Countdown timer in seconds
  const [showModal, setShowModal] = useState(true); // State for modal visibility
  const [password, setPassword] = useState(""); // State to track password input
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State to show password error

  // handle password reveal
  const handleRevealClick = () => {
    setShowModal(true); // Show modal for password entry
  };

  // Countdown timer for password verification
  useEffect(() => {
    let timer;
    if (isRevealed && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (isRevealed && countdown === 0) {
      // Clear and unmount Stripe elements
      const cardNumberElement = elements?.getElement(
        "issuingCardNumberDisplay"
      );
      const cardExpiryElement = elements?.getElement(
        "issuingCardExpiryDisplay"
      );
      const cardCvcElement = elements?.getElement("issuingCardCvcDisplay");
      const cardCopyElement = elements?.getElement("issuingCardCopyButton");

      if (cardNumberElement) cardNumberElement.unmount();
      if (cardExpiryElement) cardExpiryElement.unmount();
      if (cardCvcElement) cardCvcElement.unmount();
      if (cardCopyElement) cardCopyElement.unmount();

      setIsRevealed(false); // Hide details
    }

    return () => clearInterval(timer);
  }, [isRevealed, countdown, elements]);

  // hiding, replacing, and re-showing icons upon copy click
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const hideAndShowSuccess = (iconElementId, successIconElementId) => {
    const el = document.getElementById(iconElementId);
    el.style.display = "none";
    const elSuccess = document.getElementById(successIconElementId);
    elSuccess.style.display = "block";
    timeout(2000).then(() => {
      elSuccess.style.display = "none";
      el.style.display = "block";
    });
  };

  // initializing stripe
  useEffect(() => {
    const initializeStripe = async () => {
      setIsInitializing(true);
      try {
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        const elements = stripe.elements();
        setElements(elements);

        const nonceResult = await stripe.createEphemeralKeyNonce({
          issuingCard: cardIdMob,
        });
        setNonce(nonceResult.nonce);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      } finally {
        setIsInitializing(false);
      }
    };

    initializeStripe();
  }, [cardIdMob]);

  // generating ephemeral key
  const genarateKey = async () => {
    setIsGeneratingKey(true);
    setShowModal(false);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/create-card-app/generate-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: userId,
            card_id: cardIdMob,
            password,
          }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        setIsRevealed(true);
        setCountdown(60); // Start 60-second countdown
        setShowModal(false); // Close modal
        setPassword(""); // Reset password
        setErrorMessage(""); // Reset error
        setEphemeralKeySecret(result?.ephemeralKeySecret?.secret);
        toast.success("Authorization successful");
      }

      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setIsGeneratingKey(false);
    }
  };

  // displaying card details
  useEffect(() => {
    if (nonce && ephemeralKeySecret && cardIdMob) {
      const cardNumber = elements.create("issuingCardNumberDisplay", {
        issuingCard: cardIdMob,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: {
          base: {
            fontSize: "20px",
            lineHeight: "20px",
            color: "#fff",
          },
        },
      });
      cardNumber.mount("#card-number");

      const cardCvc = elements.create("issuingCardCvcDisplay", {
        issuingCard: cardIdMob,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: {
          base: {
            fontSize: "12px",
            lineHeight: "12px",
            color: "#fff",
          },
        },
      });

      cardCvc.mount("#card-cvc");
      const cardExpiry = elements.create("issuingCardExpiryDisplay", {
        issuingCard: cardIdMob,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: {
          base: {
            fontSize: "12px",
            lineHeight: "12px",
            color: "#fff",
          },
        },
      });

      cardExpiry.mount("#card-expiry");

      const cardNumberCopy = elements.create("issuingCardCopyButton", {
        toCopy: "number",
        style: {
          base: {
            fontSize: "10px",
            lineHeight: "10px",
          },
        },
      });

      cardNumberCopy.mount("#card-number-copy");

      cardNumberCopy.on("click", () => {
        hideAndShowSuccess("card-number-copy", "card-number-copy-success");
      });
    }
  }, [nonce, ephemeralKeySecret, cardIdMob]);

  //   if (isInitializing || cardDetailsLoading || !cardId) return <Loader />;

  if (isInitializing) return <Loader />;

  return (
    <div className="container ">
      {/* Modal */}
      {showModal && (
        <div className="credit-modal-overlay">
          <div className="credit-modal">
            <h6 className="text-title mb-3 text-start">
              <FaLock /> Enter Password
            </h6>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="*********"
                className="form-control mb-2"
              />

              <span
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {errorMessage && (
              <p className="text-danger small">{errorMessage}</p>
            )}
            <div className="credit-modal-actions">
              <button onClick={genarateKey} className="btn btn-primary me-2">
                Submit
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  setPassword("");
                  setErrorMessage("");
                }}
                className="btn btn-secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="card-summary mt-5 py-5 ">
        {/* Left Section: Summary Details */}

        {/* Right Section: Card and Balance */}
        <div className={`card-display`}>
          <div className="credit-card__actions pb-3">
            <span
              onClick={handleRevealClick}
              className={`credit-card__reveal-button ${
                isRevealed && !isGeneratingKey ? "hide" : ""
              } `}
            >
              {isGeneratingKey ? (
                "Please wait..."
              ) : (
                <>
                  {isRevealed ? null : "Reveal"}
                  {isRevealed ? null : <FaEyeSlash />}
                </>
              )}
            </span>
            {isRevealed && (
              <span className="credit-card__countdown">{countdown}s</span>
            )}
          </div>

          {isRevealed ? (
            <>
              <div
                className={`credit-card credit-card--${theme} card-container `}
              >
                <div className="credit-card__background">
                  <img
                    src={logo}
                    alt="Card Logo"
                    className="credit-card__logo"
                  />
                </div>
                <div className="credit-card__content">
                  <div
                    id="card-number"
                    className={`credit-card__number ${
                      !isRevealed ? "d-none" : ""
                    }`}
                  ></div>
                  <div
                    id="card-number-copy"
                    className={!isRevealed ? "d-none" : ""}
                  ></div>
                  <div
                    id="card-number-copy-success"
                    className={!isRevealed ? "d-none" : ""}
                  ></div>

                  <div className="credit-card__additional-details pt-5">
                    <div>
                      <span>Expires</span>
                      <div
                        id="card-expiry"
                        className={!isRevealed ? "d-none" : ""}
                      ></div>
                    </div>
                    <div className="d-flex align-items-start">
                      <div>
                        <span>CVC</span>
                        <div
                          id="card-cvc"
                          className={!isRevealed ? "d-none" : ""}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={`credit-card credit-card--${theme} card-container `}
              >
                <div className="credit-card__background">
                  <img
                    src={logo}
                    alt="Card Logo"
                    className="credit-card__logo"
                  />
                </div>
                <div className="credit-card__content">
                  <div className="credit-card__number">**** **** **** ****</div>

                  <div className="credit-card__additional-details pt-5">
                    <div>
                      <span>Expires</span>
                      <p>**/**</p>
                    </div>
                    <div className="d-flex align-items-start">
                      <div>
                        <span>CVC</span>
                        <p> *** </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardDetailsMobile;
