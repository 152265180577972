// PaymentAccount.js
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../common/components/loader/Loader";
import useGetCardAccount from "../../../../hooks/data-hooks/influencer/cms/useGetCardAccount";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";
import { useSelector } from "react-redux";
import CreateCardForm from "./CreateCardForm";
import CardList from "./CardList";

const CardManagement = () => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isEligibiltyLoading, setIsEligibiltyLoading] = useState(true);
  const [cardEligibility, setCardEligibility] = useState(false);
  const [isGettingCard, setIsGettingCard] = useState(false);
  const [isTermsLoading, setIsTermsLoading] = useState(false);
  const { data1, isLoading1, isError1, refetchData } = useGetCardAccount();
  const [showCreateCardForm, setShowCreateCardForm] = useState(false);
  const [refetchKey, setRefetchKey] = useState(true);

  const checkCardEligibility = async () => {
    try {
      setIsEligibiltyLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/payment-account/check-card-eligibility`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        toast.error(text.message);
        return;
      }

      const data = await response.json();
      setCardEligibility(data.cardEligibility);
      toast.success(data.message);
    } catch (error) {
      toast.error("Verification failed, please try again.");
    } finally {
      setIsEligibiltyLoading(false);
    }
  };

  useEffect(() => {
    checkCardEligibility();
  }, []);

  useEffect(() => {
    if (isError1) {
      toast.error(isError1);
    }
  }, [isError1]);

  const handleGetCard = async () => {
    setIsGettingCard(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/payment-account/request-for-card-holder-account`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        toast.error(text.message);
        return;
      }

      const data = await response.json();
      toast.success(data.message);
      refetchData();
    } catch (error) {
      toast.error("Request failed, please try again.");
    } finally {
      setIsGettingCard(false);
    }
  };
  const handleTerms = async () => {
    setIsTermsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/payment-account/user-terms-acceptance`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.json();
        toast.error(text.message);
        return;
      }

      const data = await response.json();
      toast.success(data.message);
      refetchData();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsTermsLoading(false);
    }
  };

  const formattedAddress = `${data1?.cardholder?.line1}, ${data1?.cardholder?.line2}, ${data1?.cardholder?.city}, ${data1?.cardholder?.state}, ${data1?.cardholder?.country}, ${data1?.cardholder?.postal_code}`;

  if (isLoading1 || isEligibiltyLoading) return <Loader />;

  return (
    <div className="cms-section">
      <div className="cms-card">
        <div className="d-flex align-items-center gap-2">
          <h4 className="text-title fw-bold m-0">Card Management</h4>
          <div className="d-flex gap-3 ">
            {cardEligibility === true && data1 === undefined ? (
              <button
                className="btn bg-primary-color text-white "
                onClick={handleGetCard}
                disabled={isGettingCard} // Disable when verifying
              >
                {isGettingCard
                  ? "Requesting..."
                  : "Request for card holder account"}
              </button>
            ) : cardEligibility === false && data1 === undefined ? (
              <span className="text-danger">
                Not Eligible to create card holder account
              </span>
            ) : null}
          </div>
          <hr />
        </div>
        <hr />

        {data1?.cardholder?.status === "active" ? (
          <>
            <div className="row mt-4 align-items-start">
              <h5 className=" text-title my-4 ">
                Connected Stripe Account Info
              </h5>
              <div className="col-12 col-lg-6">
                <div className="mb-2">
                  <span className=" text-title ">Stripe Account : </span>
                  <span className="text-break">
                    {data1?.cardholder?.stripeCardHolderId ??
                      "Info unavailable"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className=" text-title ">Email : </span>
                  <span className="text-break">
                    {data1?.cardholder?.email ?? "Info unavailable"}
                  </span>
                </div>

                <div className="mb-2">
                  <span className=" text-title ">Phone : </span>
                  <span className="">
                    {data1?.cardholder?.phone_number ?? "Info unavailable"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className=" text-title ">Name : </span>
                  <span className="">
                    {data1?.cardholder?.name ?? "Info unavailable"}
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-6 ">
                <div className="mb-2">
                  <span className=" text-title ">Status : </span>
                  <span className="">
                    {data1?.cardholder?.status ?? "Info unavailable"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className=" text-title ">Type : </span>
                  <span className="">
                    {data1?.cardholder?.type ?? "Info unavailable"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className=" text-title ">Created At : </span>
                  <span className="">
                    {getFormattedDate(data1?.cardholder?.createdAt) ??
                      "Info unavailable"}
                  </span>
                </div>

                <div className="mb-2">
                  <span className=" text-title ">Address : </span>
                  <span className="">
                    {formattedAddress ?? "Info unavailable"}
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div className="my-4">
              <span className=" text-title fs-4 ">Card Holder Account : </span>
              <span className="text-break text-success">
                {data1?.stripeCardholder?.id ?? "Info unavailable"}
              </span>
            </div>
            <hr />

            {data1?.cardholder?.userTermsAcceptance ? (
              <>
                <div className="mt-4">
                  <CardList refetchKey={refetchKey} />
                </div>

                <hr />
                {!showCreateCardForm && (
                  <button
                    onClick={() => setShowCreateCardForm(true)}
                    className="btn bg-primary-color text-white "
                  >
                    Create New Card
                  </button>
                )}
                {showCreateCardForm && (
                  <CreateCardForm
                    setShowCreateCardForm={setShowCreateCardForm}
                    setRefetchKey={setRefetchKey}
                  />
                )}
              </>
            ) : (
              <>
                <hr />
                <h5 className="fw-bold  mb-3">
                  {" "}
                  Accept Terms to create new card{" "}
                </h5>
                <button
                  className="btn bg-primary-color text-white "
                  onClick={handleTerms}
                  disabled={isTermsLoading} // Disable when verifying
                >
                  {isTermsLoading ? "Requesting..." : "Accept Terms"}
                </button>
              </>
            )}
          </>
        ) : data1?.cardholder?.status === "pending" ? (
          <>
            (<span>Please wait for admin approval</span>)
          </>
        ) : data1?.cardholder?.status === "rejected" ? (
          <>
            (<span>Admin rejected your request</span>)
          </>
        ) : null}
      </div>
    </div>
  );
};
export default CardManagement;
