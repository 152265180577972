// AdminRouter.js
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// Import Admin panel components
import AdminLayout from "../modules/admin/components/layout/AdminLayout";
import AdminDashboard from "../modules/admin/components/pages/Dashboard";
import Influencer from "../modules/admin/components/pages/Influencer.js";
import InfluencerDetails from "../modules/admin/components/pages/InfluencerDetails";
import Advertiser from "../modules/admin/components/pages/Advertiser.js";
import AdvertiserDetails from "../modules/admin/components/pages/AdvertiserDetails";
import PageNotFound from "../modules/common/components/PageNotFound.js";
import { adminRoutes } from "./../routes/all-routes/adminRoutes";
import Loader from "../modules/common/components/loader/Loader.js";
//import Settings from "../modules/admin/components/pages/Settings.js";
import AdminAuthGuard from "../hocs/layout-guards/AdminAuthGuard.js";
import Orders from "../modules/admin/components/pages/Orders.js";
import Disputes from "../modules/admin/components/pages/Disputes.js";
import ProductDetails from "../modules/admin/components/pages/ProductDetails.js";
import OrderDetails from "../modules/admin/components/pages/OrderDetails.js";
import CreateProductCategory from "../modules/admin/components/pages/CreateProductCategory.js";
import UpdateProductCategory from "../modules/admin/components/pages/UpdateProductCategory.js";
import Message from "../modules/admin/components/message/Message.js";
import WebContentSettings from "../modules/admin/components/pages/WebContentSettings.js";
import GeneralSettings from "../modules/admin/components/pages/GeneralSettings.js";
import Transaction from "../modules/admin/components/pages/Transaction.js";
import TransactionDetails from "../modules/admin/components/pages/TransactionDetails.js";
import Payment from "../modules/admin/components/pages/Payment.js";
import PaymentDetails from "../modules/admin/components/pages/PaymentDetails.js";
import Report from "../modules/admin/components/pages/Report.js";
import ReportDetails from "../modules/admin/components/pages/ReportDetails.js";
import Category from "../modules/admin/components/pages/Category.js";
import CreateCategory from "../modules/admin/components/pages/CreateCategory.js";
import UpdateCategory from "../modules/admin/components/pages/UpdateCategory.js";
import Tag from "../modules/admin/components/pages/Tag.js";
import UpdateTag from "../modules/admin/components/pages/UpdateTag.js";
import CreateTag from "../modules/admin/components/pages/CreateTag.js";
import Packages from "../modules/admin/components/pages/Packages.js";
import PackageDetails from "../modules/admin/components/pages/PackageDetails.js";
import CreateFeature from "../modules/admin/components/pages/CreateFeature.js";
import UpdateFeature from "../modules/admin/components/pages/UpdateFeature.js";
import Feature from "../modules/admin/components/pages/Feature.js";
import CardManagement from "../modules/admin/components/pages/CardManagement.js";
import CardManagementDetails from "../modules/admin/components/pages/CardManagementDetails.js";
import CardList from "../modules/admin/components/pages/CardList.js";
import CardDetails from "../modules/admin/components/pages/CardDetails.js";
import Log from "../modules/admin/components/pages/Log.js";
import LogDetails from "../modules/admin/components/pages/LogDetails.js";

const AdminRouter = () => {
  return (
    <AdminAuthGuard>
      <AdminLayout>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={adminRoutes.DASHBOARD} element={<AdminDashboard />} />
            <Route path={adminRoutes.ADVERTISER} element={<Advertiser />} />
            <Route
              path={`${adminRoutes.ADVERTISER_DETAILS}/:id`}
              element={<AdvertiserDetails />}
            />
            <Route path={adminRoutes.INFLUENCER} element={<Influencer />} />
            <Route
              path={`${adminRoutes.INFLUENCER_DETAILS}/:id`}
              element={<InfluencerDetails />}
            />
            <Route path={adminRoutes.PACKAGES} element={<Packages />} />

            <Route
              path={`${adminRoutes.PACKAGES}/:id`}
              element={<PackageDetails />}
            />
            <Route path={adminRoutes.DISPUTES} element={<Disputes />} />
            <Route
              path={adminRoutes.CREATE_DISPUTES}
              element={<CreateProductCategory />}
            />
            <Route
              path={`${adminRoutes.DISPUTES}/:id`}
              element={<UpdateProductCategory />}
            />
            <Route path={adminRoutes.ORDERS} element={<Orders />} />
            <Route
              path={`${adminRoutes.ORDERS}/:id`}
              element={<OrderDetails />}
            />
            <Route path={adminRoutes.TRANSACTION} element={<Transaction />} />
            <Route
              path={`${adminRoutes.TRANSACTION}/:id`}
              element={<TransactionDetails />}
            />
            <Route path={adminRoutes.PAYMENT} element={<Payment />} />
            <Route
              path={`${adminRoutes.PAYMENT}/:id`}
              element={<PaymentDetails />}
            />
            <Route path={adminRoutes.MESSAGE} element={<Message />} />
            <Route path={`${adminRoutes.MESSAGE}/:id`} element={<Message />} />
            {/* <Route path={adminRoutes.SETTINGS} element={<Settings />} /> */}
            <Route
              path={adminRoutes.WEB_CONTENT_SETTINGS}
              element={<WebContentSettings />}
            />
            <Route
              path={adminRoutes.GENERAL_SETTINGS}
              element={<GeneralSettings />}
            />
            <Route path={adminRoutes.CATEGORY} element={<Category />} />
            <Route
              path={adminRoutes.CREATE_CATEGORY}
              element={<CreateCategory />}
            />
            <Route
              path={`${adminRoutes.UPDATE_CATEGORY}/:id`}
              element={<UpdateCategory />}
            />

            {/* ---------------Tag------------------- */}
            <Route path={adminRoutes.TAG} element={<Tag />} />
            <Route path={adminRoutes.CREATE_TAG} element={<CreateTag />} />
            <Route
              path={`${adminRoutes.UPDATE_TAG}/:id`}
              element={<UpdateTag />}
            />

            {/* ---------------Feature------------------- */}
            <Route path={adminRoutes.FEATURE} element={<Feature />} />
            <Route
              path={adminRoutes.CREATE_FEATURE}
              element={<CreateFeature />}
            />
            <Route
              path={`${adminRoutes.UPDATE_FEATURE}/:id`}
              element={<UpdateFeature />}
            />

            {/* Report */}
            <Route path={adminRoutes.REPORT} element={<Report />} />
            <Route
              path={`${adminRoutes.REPORT}/:id`}
              element={<ReportDetails />}
            />

            <Route
              path={adminRoutes.CARD_HOLDERS}
              element={<CardManagement />}
            />
            <Route
              path={`${adminRoutes.CARD_HOLDER_DETAILS}/:id/:influencerId`}
              element={<CardManagementDetails />}
            />
            <Route
              path={`${adminRoutes.LOG_DETAILS}/:id`}
              element={<LogDetails />}
            />
            <Route path={adminRoutes.CARD_LIST} element={<CardList />} />
            <Route
              path={`${adminRoutes.CARD_DETAILS}/:id`}
              element={<CardDetails />}
            />

            <Route path={adminRoutes.LOG} element={<Log />} />

            {/* import report */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </AdminLayout>
    </AdminAuthGuard>
  );
};

export default AdminRouter;
