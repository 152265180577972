// MainRouter.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import panel-specific routers
import AdminRouter from "./AdminRouter";
import ClinicRouter from "./ClinicRouter";
// import LabRouter from "./LabRouter";
import AuthRouter from "./AuthRouter";
import WebRouter from "./WebRouter";

import VerifySignUpOtp from "./../modules/auth/components/pages/VerifySignUpOtp";
import VerifySignUpOtpAdvertiser from "./../modules/auth/components/pages/VerifySignUpOtpAdvertiser";
import ForgetPasswordOtp from "./../modules/auth/components/pages/ForgetPasswordOtp";

// Import error components
import PageNotFound from "./../modules/common/components/PageNotFound";
import ForbiddenError from "./../modules/common/components/ForbiddenError";
import ServerError from "./../modules/common/components/ServerError";
import NetworkError from "./../modules/common/components/NetworkError";

import {
  authPrefix,
  authPrefixedRoutes
} from "./../routes/all-routes/authRoutes.js"; // Import common routes
import { commonRoutes } from "./../routes/all-routes/commonRoutes"; // Import common routes
import UnAuthGuard from "../hocs/layout-guards/UnAuthGuard"; // Import the UnAuthGuard component
import ForgetPassword from "../modules/auth/components/pages/ForgetPassword.js";
import AuthLayout from "../modules/auth/components/layout/AuthLayout.js";
import ResetPassword from "../modules/auth/components/pages/ResetPassword.js";
import InfluencerRouter from "./InfluencerRouter.js";
import AdvertiserRouter from "./AdvertiserRouter.js";
import CardRouter from "./CardRouter.js";

const MainRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Panel-specific routes */}
        <Route path="/card/*" element={<CardRouter />} />
        <Route path="/admin/*" element={<AdminRouter />} />
        <Route path="/clinic/*" element={<ClinicRouter />} />
        <Route path="/influencer/*" element={<InfluencerRouter />} />
        <Route path="/advertiser/*" element={<AdvertiserRouter />} />
        <Route path={`/${authPrefix}/*`} element={<AuthRouter />} />

        {/* UnAuthGuard wrapped routes */}
        <Route
          path={authPrefixedRoutes.VERIFY_SIGNUP_OTP}
          element={
            <UnAuthGuard>
              <VerifySignUpOtp />
            </UnAuthGuard>
          }
        />
        <Route
          path={authPrefixedRoutes.VERIFY_SIGNUP_OTP_ADVERTISER}
          element={
            <UnAuthGuard>
              <VerifySignUpOtpAdvertiser />
            </UnAuthGuard>
          }
        />
        <Route
          path={commonRoutes.FORGET_PASSWORD_OTP}
          element={
            <UnAuthGuard>
              <ForgetPasswordOtp />
            </UnAuthGuard>
          }
        />

        {/* Web panel route */}

        {/* Use the WebRouter here */}
        <Route path="/*" element={<WebRouter />} />

        {/* Error routes */}
        <Route path={commonRoutes.FORBIDDEN} element={<ForbiddenError />} />
        <Route path={commonRoutes.SERVER_ERROR} element={<ServerError />} />
        <Route path={commonRoutes.NETWORK_ERROR} element={<NetworkError />} />
        {/* Route for Page Not Found */}
        <Route path={commonRoutes.PAGE_NOT_FOUND} element={<PageNotFound />} />
        {/* Route for forget password */}
        <Route
          path={commonRoutes.FORGET_PASSWORD}
          element={
            <UnAuthGuard>
              <AuthLayout>
                <ForgetPassword />
              </AuthLayout>
            </UnAuthGuard>
          }
        />
        {/* Route for forget password */}
        <Route
          path={commonRoutes.RESET_PASSWORD}
          element={
            <UnAuthGuard>
              <AuthLayout>
                <ResetPassword />
              </AuthLayout>
            </UnAuthGuard>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;
