import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useCardRequirement from "../../../../hooks/data-hooks/admin/generalsetting/useCardRequirement";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const CardRequirement = () => {
  const {
    isLoadingCardRequirement,
    cardRequirement,
    updateContent,
    submitSuccess,
    isError,
    isUpdating,
  } = useCardRequirement();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (cardRequirement) {
      setValue(
        "totalEarningsByTheUser",
        cardRequirement.totalEarningsByTheUser
      );
      setValue("totalPlatformRevenue", cardRequirement.totalPlatformRevenue);
    }
  }, [cardRequirement]);

  // useEffect(() => {
  //   // Show error toast if isError is true
  //   if (isError) {
  //     toast.error(isError);
  //   }
  // }, [isError]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    // Prevent form submission if there is an ongoing login process
    try {
      // Call the custom hook for API call
      updateContent(data);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoadingCardRequirement) return <Loader />;

  return (
    <div>
      <Helmet>
        <title>Card Requirement | Admin | {process.env.REACT_APP_NAME}</title>
      </Helmet>

      <form
        className="cms-form mb-4"
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        <div className="fs-18px fw-bold mb-3">
          Required Total Earnings By The User
        </div>
        <hr />

        <div className="form-item">
          {/* <label>Value (in %)</label> */}
          <input
            type="number"
            placeholder={`Enter value here`}
            {...register(`totalEarningsByTheUser`)}
            required
          />
        </div>

        <div className="fs-18px fw-bold mb-3">
          Required Total Platform Revenue
        </div>
        <hr />

        <div className="form-item">
          {/* <label>Value (in %)</label> */}
          <input
            type="number"
            placeholder={`Enter value here`}
            {...register(`totalPlatformRevenue`)}
            required
          />
        </div>
        <Button
          label={isUpdating ? "Submitting..." : "Submit"}
          type="submit"
          className={`w-100  ${isUpdating ? "bg-disabled" : ""}`}
          disabled={isUpdating}
        />
      </form>
    </div>
  );
};

export default CardRequirement;
