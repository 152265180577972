import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet-async";
import { SearchIcon } from "../../../../utils/MyIcons";
import { Link } from "react-router-dom";
import useClinicListLogic from "../../../../hooks/data-hooks/admin/clinic-management/useClinicListLogic";

import MyImage from "../../../../custom-components/MyImage";
import TableShimmer from "../../../common/components/shimmers/TableShimmer";
import PaginationShimmer from "../../../common/components/shimmers/PaginationShimmer";
import { Str } from "@jimuelpalaca/str";
import { getItemNumber } from "../../../../helpers/textHelpers";
import NoDataInTable from "../../../common/components/blanks/NoDataInTable";
import adminPrefixedRoutes, {
  adminPageNames,
} from "../../../../routes/all-routes/adminRoutes";

const Advertiser = () => {
  const {
    register,
    pageSize,
    handleSubmit,
    onSubmit,
    isSubmitting,
    currentPage,
    setCurrentPage,
    handlePageClick,
    searchTerm,
    filterStatus,
    setFilterStatus,
    clinicList,
    isLoading,
    clinicListError,
    clinicListMeta,
    debouncedSubmit,
    clinicStates,
    handleVerifyClinic,
    handleActiveDeactiveClinic,
  } = useClinicListLogic();

  // console.log(clinicList);

  return (
    <>
      <Helmet>
        {adminPageNames.ADVERTISER} | Admin | {process.env.REACT_APP_NAME}
      </Helmet>
      <div className="container">
        <div className="shadow ">
          <h5 className=" p-4 bg-white rounded-top-3 mb-0 fw-bold">
            Advertiser List
          </h5>
          <div className="p-4 bg-white rounded-bottom-3 table-responsive vstack gap-4">
            <div className="vstack gap-4">
              {/* table header */}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex justify-content-between align-items-center"
              >
                {/* table filter */}

                <div className="d-flex gap-2">
                  <select
                    className="form-select outline-none"
                    aria-label="Status"
                    name="status"
                    {...register("status")}
                    onChange={(event) => {
                      const selectedStatus = event.target.value;
                      setFilterStatus(selectedStatus); // Update filterStatus state
                      onSubmit({ searchTerm, status: selectedStatus }); // Trigger onSubmit directly
                    }}
                  >
                    <option value="">Filter</option>
                    <option value="active">Active</option>
                    <option value="pending">Pending</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                {/* table search */}
                {/* <div className="d-flex gap-2">
                  <div className=" search border border-1 rounded-3 d-flex align-items-center">
                    <span className="ps-3">
                      <SearchIcon />
                    </span>
                    <input
                      type="text"
                      className="rounded-3 p-2 w-min-250px border-0 bg-transparent "
                      placeholder="Search"
                      aria-label="Search"
                      name="searchTerm"
                      {...register("searchTerm")}
                      onChange={(event) =>
                        debouncedSubmit({
                          searchTerm: event.target.value,
                          status: filterStatus,
                        })
                      }
                    />
                  </div>
                </div> */}
              </form>
              {/* table */}
              <div className="table-responsive min-h-600px border rounded-bottom-3">
                {(isLoading || clinicListError) && true && <TableShimmer />}
                {!isLoading && !clinicListError && clinicList.length === 0 && (
                  <NoDataInTable />
                )}
                {!isLoading && !clinicListError && clinicList.length > 0 && (
                  <table className="table table-bordered align-middle">
                    <thead>
                      <tr>
                        <th
                          colSpan={1}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          #No
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Advertiser
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Email
                        </th>
                        <th
                          colSpan={3}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Phone
                        </th>
                        <th
                          colSpan={3}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Status
                        </th>
                        <th
                          colSpan={2}
                          scope="col"
                          className="bg-secondary-color ps-3"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clinicList.map((clinic, index) => (
                        <tr key={index}>
                          <td
                            colSpan={1}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {getItemNumber(pageSize, currentPage, index)}
                          </td>
                          <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text deco"
                          >
                            <Link
                              to={`${adminPrefixedRoutes.ADVERTISER_DETAILS}/${clinic?._id}`}
                              className="link-style"
                            >
                              <div className="text-nowrap">
                                <MyImage
                                  src={clinic?.profileImageUrl}
                                  addBaseUrl={true}
                                  alt=""
                                  className="w-h-24px rounded-circle me-2 "
                                />
                                <span className="fs-14 fw-light  ">
                                  {clinic?.fullName ?? "Info unavailable"}
                                </span>
                              </div>
                            </Link>
                          </td>
                          <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {clinic?.email ?? "Info unavailable"}
                          </td>
                          <td
                            colSpan={3}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {clinic?.phoneNumber ?? "Info unavailable"}
                          </td>
                          <td
                            colSpan={3}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {clinic?.status ?? "Info unavailable"}
                          </td>

                          <td
                            colSpan={2}
                            className="ps-3 fs-14 fw-light text-text"
                          >
                            {
                              <div className="form-switch">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  role="switch"
                                  checked={clinic.status === "active"}
                                  onChange={() =>
                                    handleActiveDeactiveClinic(clinic._id)
                                  }
                                  disabled={
                                    clinicStates[clinic._id]
                                      ?.activeDeactiveClinicLoading
                                  } // Disable switch when loading
                                />
                              </div>
                            }

                            {/* {clinic.status == "pending" && (
                              <button
                                onClick={() => handleVerifyClinic(clinic._id)}
                                disabled={
                                  clinicStates[clinic._id]?.verifyClinicLoading
                                }
                                type="button"
                                className="btn btn-outline-info btn-sm"
                              >
                                {clinicStates[clinic._id]?.verifyClinicLoading
                                  ? "Verifying..."
                                  : "Verify Clinic"}
                              </button>
                            )} */}
                            {/* {clinic.status !== "pending" && (
                              <div className="form-switch">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  role="switch"
                                  checked={clinic.status === "active"}
                                  onChange={() =>
                                    handleActiveDeactiveClinic(clinic._id)
                                  }
                                  disabled={
                                    clinicStates[clinic._id]
                                      ?.activeDeactiveClinicLoading
                                  } // Disable switch when loading
                                />
                              </div>
                            )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* pagination */}
            <span className="d-flex justify-content-end">
              {(isLoading || clinicListError) && <PaginationShimmer />}
              {!isLoading &&
                !clinicListError &&
                clinicListMeta &&
                clinicList.length > 0 && (
                  <ReactPaginate
                    breakLabel=""
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    forcePage={currentPage - 1}
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(
                      clinicListMeta?.pagination?.total_page ?? 1
                    )}
                    previousLabel="Previous"
                    marginPagesDisplayed={1}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    previousLinkClassName="custom-previous-link"
                    nextLinkClassName="custom-next-link"
                    pageLinkClassName="custom-page-link"
                    activeLinkClassName="active-page-link"
                    disabledLinkClassName="disabled-page-link"
                  />
                )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Advertiser;
