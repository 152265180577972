import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Button from "../../../web/components/button/Button";
import { Link, useNavigate } from "react-router-dom";
import useGetPackageList from "../../../../hooks/data-hooks/influencer/social-profile/useGetPackageList";
import Loader from "../../../common/components/loader/Loader";
import { Helmet } from "react-helmet";
import { MdDeleteForever } from "react-icons/md";
import influencerPrefixedRoutes, {
  influencerPageNames,
} from "../../../../routes/all-routes/influencerRoutes";

const SocialProfiles = () => {
  const navigate = useNavigate();

  const {
    packages,
    isLoading,
    deletePackage,
    packageError,
    fetchPackageList,
    totalRows,
    setTotalRows,
  } = useGetPackageList();



  const columns = [
    // #NO
    {
      name: "#No",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "Platform",
      selector: (row) => (
        <Link
          to={`${influencerPrefixedRoutes.UPDATE_SOCIAL_PROFILE}/${row._id}`}
          className="link-style"
        >
          {row?.socialPlatformName},
        </Link>
      ),
      sortable: true,
    },
    
    {
      name: "Link",
      selector: (row) => row?.socialLink,
      sortable: true,
    },
   
    {
      name: "Followers",
      selector: (row) => row?.followers,
      sortable: true,
    },
    {
      name: "Engagement Rate",
      selector: (row) => row?.engagementRate,
      sortable: true,
    },
    {
      name: "Delete",
      cell: (row) => (
        <span onClick={() => deletePackage(row._id)} className="cursor-pointer">
          <MdDeleteForever color={`var(--primary-color)`} size={20} />
        </span>
      ),
    },
  ];

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "var(--secondary-color)",
        color: "#000",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {influencerPageNames.SOCIAL_PROFILES} | Influencer |
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="container">
        <div className="my-4">
          <span className="fw-bold text-title fs-5">Social Profiles List</span>
          <Button
            label={"Add New Social Profile"}
            className="ms-3"
            onClick={() =>
              navigate(influencerPrefixedRoutes.CREATE_SOCIAL_PROFILE)
            }
          />
        </div>
        <div className="shadow">
          <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={packages}
            progressPending={isLoading}
            fixedHeader
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};
export default SocialProfiles;
