import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";
import useActiveCard from "./useActiveCard";

const useVerifyClinic = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [verifyClinicLoading, setVerifyClinicLoading] = useState(false);
  const [verifyClinicError, setVerifyClinicError] = useState(null);
  const [verifyClinicSuccess, setVerifyClinicSuccess] = useState(false);
  const [id, setId] = useState(null);

  const { activeCard, activeCardLoading, activeCardError, activeCardSuccess } =
    useActiveCard();

  const {
    mutate: verifyClinic,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "ApproveCardReq",
    mutationFn: async (clinicId) => {
      try {
        setVerifyClinicLoading(true);
        setId(clinicId);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/stripe-card/create/${clinicId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // body: JSON.stringify({}),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.message);
        }

        return data;
      } catch (error) {
        setVerifyClinicError(error);
        setVerifyClinicLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      setVerifyClinicError(error);
      setVerifyClinicLoading(false);
      throw error;
    },
    onSuccess: async () => {
      activeCard(id);
      setVerifyClinicSuccess(activeCardSuccess);
      setVerifyClinicLoading(activeCardLoading);
    },
  });

  return {
    verifyClinic,
    verifyClinicLoading,
    verifyClinicError,
    verifyClinicSuccess,
  };
};

export default useVerifyClinic;
