// CardRouter.js
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import PageNotFound from "../modules/common/components/PageNotFound.js";

import Loader from "../modules/common/components/loader/Loader.js";
import CardDetailsMobile from "../modules/influencer/components/cms/CardDetailsMobile.js";

const CardRouter = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={`/:userId/:cardIdMob`} element={<CardDetailsMobile />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default CardRouter;
