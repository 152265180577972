import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useUpdateTestimonialSection from "../../../../hooks/data-hooks/admin/homepage/testimonial-section/useUpdateTestimonialSection"; // Importing custom hook for API call
import useGetTestimonialSection from "../../../../hooks/data-hooks/admin/homepage/testimonial-section/useGetTestimonialSection";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";
import MyImage from "../../../../custom-components/MyImage";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";

const TestimonialSection = () => {
  const {
    data1,
    testimonialContent,
    isLoadingGet,
    isError1,
    handleRemovePair,
    testimonialPlaceholder,
  } = useGetTestimonialSection();

  // console.log(data1);

  const [inputPairs, setInputPairs] = useState(0);
  const [files, setFiles] = useState({});
  const { handleUpload, isLoading } = useSingleImageUpload();

  const handleAddPair = () => {
    setInputPairs((prevCount) => prevCount + 1);
  };
  const handleFileChanges = (event) => {
    setFiles({ ...files, [event.target.name]: event.target.files[0] });
  };
  const {
    register,
    handleSubmit,
    formState: { state, errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    // Set default values if testimonialContent is available
    if (data1) {
      let listFile = {};
      setInputPairs(data1?.data?.content?.testimonial_items.length);
      setValue("title", data1?.data?.content?.title);
      setValue("subtitle", data1?.data?.content?.subtitle);
      data1?.data?.content?.testimonial_items.forEach((item, index) => {
        listFile = {
          ...listFile,
          [`img-${index}`]: item.image,
        };
        setValue(`testimonial_items[${index}].image`, item?.image);
        setValue(`testimonial_items[${index}].content`, item?.content);
        setValue(`testimonial_items[${index}].name`, item?.name);
        setValue(`testimonial_items[${index}].designation`, item?.designation);
      });
    }
  }, [data1]);

  const { updateContent, isLoadingUpdate, isError, error, submitSuccess } =
    useUpdateTestimonialSection();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  // useEffect(() => {
  //   // Show success toast if submitSuccess is true
  //   if (submitSuccess) {
  //     toast.success("Form Submit Successful");
  //   }
  // }, [submitSuccess]);

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    const newData = await Promise.all(
      data.testimonial_items?.map(async (item, index) => {
        // Check if image is an object and needs to be uploaded
        const image =
          typeof files[`img-${index}`] === "object"
            ? await handleUpload(files[`img-${index}`])
            : item.image;

        return {
          ...item,
          image: image,
          // image: "https://example.com/",
        };
      })
    );

    const formattedData = {
      ...data,
      testimonial_items: data.testimonial_items.filter(
        (item) => item?.image !== "" || item?.content !== ""
      ),
    };

    try {
      // Call the custom hook for API call
      updateContent({
        ...data,
        testimonial_items: newData.filter(
          (item) => item?.image !== "" || item?.content !== ""
        ),
      });
    } catch (error) {
      // Error handling is done in useEffect
    }
  };
  if (isLoadingGet) return <Loader />;

  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4  " onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold mb-3">Testimonial Section</div>

          <div className="cms-content custom-scroll">
            <div className="form-item">
              <label className="">Section Title</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter title here"
                  {...register("title", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            {/* <div className="form-item">
              <label className="">Subtitle</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter subtitle here"
                  {...register("subtitle", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div> */}

            {/* Mapping over testimonial_items */}
            {Array.from({ length: inputPairs }).map((_, index) => (
              <div key={index}>
                <hr className="py-3" />
                <div className="form-item">
                  <label>Reviewer Name {index + 1}</label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder="Enter content here"
                      {...register(`testimonial_items[${index}].name`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>

                <div className="form-item">
                  <label>Reviewer Designation {index + 1}</label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder="Enter content here"
                      {...register(`testimonial_items[${index}].designation`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <div className="d-flex justify-content-between">
                    <label>
                      Image {index + 1}
                      <span className="text-danger fs-10 ">(Max 5 MB)</span>
                    </label>
                    <button
                      type="button"
                      className="border-0 px-2"
                      onClick={() => {
                        handleRemovePair(
                          index,
                          data1?.data?.content?.testimonial_items[index]?.image
                        );
                        const updatedFiles = { ...files };
                        let newFiles = {};
                        delete updatedFiles[index];
                        Object.entries(updatedFiles).forEach(
                          ([key, val], index) => {
                            newFiles = { ...newFiles, [index]: val };
                          }
                        );
                        // Set the state with the updated files object
                        setFiles(newFiles);
                        setInputPairs((prevCount) => prevCount - 1);
                        setValue(`testimonial_items[${index}].image`, "");
                        setValue(`testimonial_items[${index}].content`, "");
                        // console.log(
                        //   getValues[`testimonial_items[${index}].content`]
                        // );
                      }}
                    >
                      X
                    </button>
                  </div>
                  <div className="input-wrapper">
                    <MyImage
                      src={
                        typeof files[`img-${index}`] === "undefined"
                          ? data1?.data?.content?.testimonial_items[index]
                              ?.image
                          : URL?.createObjectURL(files[`img-${index}`])
                      }
                      className="preview-img"
                    />

                    <input
                      type="file"
                      onChange={handleFileChanges}
                      name={`img-${index}`}
                    />
                  </div>
                </div>
                <div className="form-item">
                  <label>Review Content {index + 1}</label>
                  <div className="input-wrapper">
                    <textarea
                      rows={5}
                      type="text"
                      placeholder="Enter content here"
                      {...register(`testimonial_items[${index}].content`, {
                        required: "Field is required",
                      })}
                    />
                  </div>
                </div>
              </div>
            ))}
            <button
              type="button"
              className="w-25 button-primary p-2 "
              onClick={handleAddPair}
            >
              Add More
            </button>
          </div>

          <Button
            label={isLoading || isLoadingUpdate ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoading || isLoadingUpdate ? "bg-disabled" : ""
            }`}
            disabled={isLoading || isLoadingUpdate}
          />
        </form>
      </div>
    </div>
  );
};
export default TestimonialSection;
