import React, { useEffect, useMemo } from "react";
import { Rating } from "react-simple-star-rating";
import advertiserImg from "../../../../assets/image/packages/advertiser.jpg";

// import ReactPaginate from "react-paginate";
// import { clinicTotalOrderData } from "../../../../dummy-data/admin/pages/user/Clinic/clinicTotalOrderData";
import { useState } from "react";
import { StarIcon } from "../../../../utils/MyIcons";
// import Table2 from "../table/Table2";
// import Map from "../map/Map";
import useClinicDetails from "../../../../hooks/data-hooks/admin/clinic-management/useClinicDetails";
import { Link, useParams } from "react-router-dom";
import MyImage from "../../../../custom-components/MyImage";
import { Str } from "@jimuelpalaca/str";
import ProfileBarShimmer from "../../../common/components/shimmers/ProfileBarShimmer";
import DataTable from "react-data-table-component";
import BackButton from "../../../../helpers/backButton";
// import Loader from "../../../common/components/loader/Loader";

const AdvertiserDetails = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle page change
  // const handlePageClick = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  // -- real Logic

  let { id } = useParams();


  const {
    fetchClinicDetailsWithId,
    isLoading,
    isError,
    clinicDetails,
    clinicDetailsError,
    orderList,
    fetchOrdersOfClinic,
  } = useClinicDetails(id);

  useEffect(() => {
    fetchClinicDetailsWithId(id); // Fetch clinic details when clinicId changes
  }, [id]);

  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#F5FAFF",
      },
    },
  };

  const handlePageChange = (page) => {
    fetchOrdersOfClinic(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchOrdersOfClinic(newPerPage, page);
    setCurrentPage(page);
    setPerPage(newPerPage);
  };

  // const columnsOfOrderList = useMemo(
  //   () => [
  //     {
  //       name: "#",
  //       selector: (row, index) => index + 1,
  //       sortable: true,
  //     },
  //     {
  //       name: "Order Id",
  //       selector: (row) => row.orderId,
  //       sortable: true,
  //     },
  //     {
  //       name: "Ordered By",
  //       selector: (row) => row.userId.email,
  //       sortable: true,
  //     },

  //     {
  //       name: "Order Status",
  //       selector: (row) => row.orderStatus,
  //       sortable: true,
  //     },
  //     {
  //       name: "Payment Status",
  //       selector: (row) => row.paymentStatus,
  //       sortable: true,
  //     },
  //     {
  //       name: "Delivery Status",
  //       selector: (row) => row.deliveryStatus,
  //       sortable: true,
  //     },
  //   ],
  //   []
  // );

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    // {
    //   name: "#",
    //   selector: (row, index) =>
    //     (data?.meta?.pagination?.current_page - 1) * perPage + index + 1,
    //   sortable: true,
    // },
    {
      name: "Order Id",
      selector: (row) => (
        <Link to={`/admin/orders/${row._id}`} className="link-style">
          {row?.orderNumber}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Ordered By",
      selector: (row) => {
        return row?.advertiserId?.email;
      },

      sortable: true,
    },
    {
      name: "Advertiser",
      selector: (row) => {
        return row?.advertiserId?.fullName;
      },
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => {
        return row?.orderStatus;
      },
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => {
        return row.paymentStatus;
      },
      sortable: true,
    },
    // {
    //   name: "Delivery Status",
    //   selector: (row) => {
    //     return row.deliveryStatus;
    //   },
    //   sortable: true,
    // },
    // {
    //   name: "Messages",
    //   cell: (row) => (
    //     <Link to={`${adminPrefixedRoutes.MESSAGE}/${row?._id}`} className="">
    //       <MessageBadge messageCount={row.totalMessages} />
    //     </Link>
    //   ),
    // },
  ];



  return (
    <div className="container ">
      <BackButton />
      <h5>
        Advertiser Profile :&nbsp;
        {!isLoading && clinicDetails
          ? clinicDetails?.userInfo?.fullName
          : "Loading..."}
      </h5>

      <div className="d-flex flex-column flex-lg-row gap-3 ">
        {/* Profile */}
        <div className="bg-white rounded-3 p-4 w-lg-25 ">
          {(isLoading || isError) && <ProfileBarShimmer />}
          {!isLoading && !isError && clinicDetails && (
            <>
              <div className="text-center">
                <MyImage
                  src={clinicDetails?.userInfo?.profileImageUrl}
                  addBaseUrl={true}
                  alt=""
                  className="w-h-130px rounded-circle border border-5  border-primary-color my-3"
                />
                <div className="mt-2 vstack gap- ">
                  <span className="fw-bold text-title ">
                    {clinicDetails?.userInfo?.fullName ?? "Name unavailable"}
                  </span>
                  <span className="fs-14 ">
                    {clinicDetails?.userInfo?.phoneNumber ??
                      "Phone number  unavailable"}
                  </span>
                  <span className="fs-14 ">
                    Status :{" "}
                    {Str.snakeToWord(
                      clinicDetails?.userInfo?.status ?? "unknown"
                    )}
                  </span>
                </div>
              </div>
              <hr />
              <div className="d-flex fs-12 ">
                <div className="d-flex flex-column w-50 gap-2 ">
                  {/* <span>Followers</span> */}
                  <span>Ratings</span>
                  <span>Total Feedback</span>
                </div>
                <div className="d-flex flex-column gap-2">
                  {/* <span>: 2.5 K</span> */}
                  <span>
                    : <Rating initialValue={2} readonly size={15} />
                    &nbsp;(2)
                  </span>
                  <span>: 2167</span>
                </div>
              </div>
              <hr />
              <p className="fs-12">
                We create targeted, data-driven ad campaigns that boost
                visibility and drive engagement. Our team specializes in
                crafting compelling ads across social media, search engines, and
                display networks, ensuring your message reaches the right
                audience. Focused on maximizing ROI, we help brands achieve
                measurable success through strategic placements and performance
                tracking.
              </p>
              <hr />
              <p className="fs-12">
                1234 Market Street,
                <br /> Suite 500 City,
                <br /> State,
                <br /> ZIP Code Country <br />
                <br />
                {clinicDetails?.userInfo?.email} <br />
                Website: advertiser.com
              </p>
              <img src={advertiserImg} alt="" className="w-100 rounded-3 " />
            </>
          )}
        </div>
        {/* Table */}
        <div className="flex-grow-1 ">
          <h5 className="bg-primary-color p-4 text-white rounded-top-3 mb-0 ">
            Total Orders information
          </h5>

          <DataTable
            // title={headerComponent}
            customStyles={tableHeaderstyle}
            columns={columns}
            data={orderList}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            selectableRowsHighlight
            highlightOnHover
          />
        </div>
      </div>
    </div>
  );
};
export default AdvertiserDetails;
