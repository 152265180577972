// UserAccount.js
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useGetUserAccount from "../../../../hooks/data-hooks/advertiser/cms/useGetUserAccount";
import useUpdateUserAccount from "../../../../hooks/data-hooks/advertiser/cms/useUpdateUserAccount";
import useSingleImageUpload from "../../../../hooks/file-uplod-hooks/useSingleImageUpload";
import MyImage from "../../../../custom-components/MyImage";
import Button from "../../../web/components/button/Button";
import Loader from "../../../common/components/loader/Loader";

const UserAccount = () => {
  const { data1, isLoading1, isError1 } = useGetUserAccount();
  const { isLoading, handleUpload, error } = useSingleImageUpload();

  const [files, setFiles] = useState(null);

  const handleFileChanges = (event) => {
    setFiles(event.target.files[0]);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();

  const {
    updateContent,
    isLoadingUpdate,
    isError,
    errorUpdate,
    submitSuccess,
  } = useUpdateUserAccount();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError || error || isError1 || errorUpdate) {
      toast.error(isError || error || isError1 || errorUpdate);
    }
  }, [isError, error, isError1, errorUpdate]);

  // ----------------------------
  useEffect(() => {
    // Set default values if data1 is available
    if (data1) {
      setValue("fullName", data1?.userInfo?.fullName);
      setValue("phoneNumber", data1?.userInfo?.phoneNumber);
      setValue("profileImageUrl", data1?.userInfo?.profileImageUrl);
    }
  }, [data1]);
  // ----------------------------





  const onSubmit = async (data) => {

    if (isSubmitting) return;
    try {
      const submittedUrl =
        files !== null ? await handleUpload(files) : data.profileImageUrl;

      // Explicitly removing any unintended fields
      const formattedData = {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        profileImageUrl: submittedUrl,
      };

      updateContent(formattedData);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  if (isLoading1) return <Loader />;
  return (
    <div className="cms-section ">
      <div className="cms-card  ">
        <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="fs-18px fw-bold ">User Account Information</div>

          <div className=" cms-content custom-scroll ">
            <div className="form-item">
              <label className="">
                Image <span className="text-danger fs-10 ">(Max 5 MB)</span>
              </label>
              <div className="input-wrapper">
                <MyImage
                  src={
                    files
                      ? URL.createObjectURL(files)
                      : data1?.userInfo?.profileImageUrl
                  }
                  className="preview-img"
                />

                <input type="file" onChange={handleFileChanges} />
              </div>
            </div>
            <div className="form-item">
              <label className="">Full Name</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter full name"
                  {...register("fullName", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
            
            <div className="form-item">
              <label className="">Phone Number</label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className=""
                  placeholder="Enter Phone Number"
                  {...register("phoneNumber", {
                    required: "Field is required",
                  })}
                />
              </div>
            </div>
          </div>

          <Button
            label={isLoading || isLoadingUpdate ? "Submitting" : "Submit"}
            type="submit"
            className={`w-100 ${
              isLoading || isLoadingUpdate ? "bg-disabled" : ""
            }`}
            disabled={isLoading || isLoadingUpdate}
          />
        </form>
      </div>
    </div>
  );
};
export default UserAccount;
