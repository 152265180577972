import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CreateCardForm = ({ setShowCreateCardForm, setRefetchKey }) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "virtual",
      spending_limits: [{ amount: "", interval: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "spending_limits",
  });

  const onSubmit = async (data) => {
    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/stripe-card/request`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Show success notification if the request is successful
      toast.success(response.data.message || "Card request sent successfully!");
    } catch (error) {
      console.error("API error:", error);
      const errorMsg =
        error.response?.data?.message || "Failed to send request";
      toast.error(errorMsg);
    } finally {
      setIsSubmitting(false);
      setShowCreateCardForm(false);
      setRefetchKey((prevKey) => !prevKey);
    }
  };

  const selectedIntervals = watch("spending_limits").map(
    (limit) => limit.interval
  );

  const intervalOptions = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
  ];

  return (
    <div className="">
      <h5 className="my-4 color-title-color ">Request for new Stripe Card</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Card Type Selection */}
        <div className="mb-3">
          <label className="form-label">Card Type</label>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <select className="form-select" {...field} required>
                <option value="virtual">Virtual</option>
                <option value="physical">Physical</option>
              </select>
            )}
          />
        </div>

        {/* Spending Limits */}
        <div className="mb-4">
          <label className="form-label">Spending Limits</label>
          {fields.map((field, index) => (
            <div key={field.id} className="row mb-2 align-items-start">
              <div className="col-5">
                <Controller
                  control={control}
                  name={`spending_limits.${index}.amount`}
                  rules={{
                    required: "Amount is required",
                    min: {
                      value: 1,
                      message: "Amount must be greater than 0",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      type="number"
                      placeholder="Amount"
                      className="form-control"
                      {...field}
                    />
                  )}
                />
                {errors?.spending_limits?.[index]?.amount && (
                  <div className="text-danger">
                    {errors.spending_limits[index].amount.message}
                  </div>
                )}
              </div>
              <div className="col-5">
                <Controller
                  control={control}
                  name={`spending_limits.${index}.interval`}
                  rules={{
                    required: "Interval is required",
                  }}
                  render={({ field }) => (
                    <select className="form-select" {...field}>
                      <option value="" disabled>
                        Select Interval
                      </option>
                      {intervalOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={selectedIntervals.includes(option.value)}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors?.spending_limits?.[index]?.interval && (
                  <div className="invalid-feedback">
                    {errors.spending_limits[index].interval.message}
                  </div>
                )}
              </div>
              <div className="col-2">
                {index > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          {fields.length < 3 && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => append({ amount: "", interval: "" })}
            >
              Add More
            </button>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="btn bg-primary-color color-white-cream me-3"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Send Request"}
        </button>
      </form>
    </div>
  );
};

export default CreateCardForm;
