import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useVerifyClinic = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [verifyClinicLoading, setVerifyClinicLoading] = useState(false);
  const [verifyClinicError, setVerifyClinicError] = useState(null);
  const [verifyClinicSuccess, setVerifyClinicSuccess] = useState(false);


  const {
    mutate: verifyClinic,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "ApproveCard",
    mutationFn: async (clinicId) => {
      try {
        setVerifyClinicLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/payment-account-management/create-card-holder-in-stripe/${clinicId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // body: JSON.stringify({}),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.message);
        }

        setVerifyClinicSuccess(true);
        setVerifyClinicLoading(false);
        toast.success(data.message || "Card Request Approved Successfully");
        return data;
      } catch (error) {
        setVerifyClinicError(error);
        setVerifyClinicLoading(false);

        toast.error(error.message || "Failed to approve card request");
        throw error;
      }
    },
    onError: (error) => {
      setVerifyClinicError(error);
      setVerifyClinicLoading(false);

      throw error;
    },
  });

  return {
    verifyClinic,
    verifyClinicLoading,
    verifyClinicError,
    verifyClinicSuccess,
  };
};

export default useVerifyClinic;
