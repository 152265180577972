// useGetPaymentUrl.js
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { toast } from "react-toastify";
import { useState } from "react";

const useGetPaymentUrl = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isGenratingUrl, setIsGenratingUrl] = useState(false);

  const {
    mutate: getPaymentUrl,
    isLoading,
    isError,
    data,
  } = useMutation({
    mutationKey: "getPaymentUrl",
      mutationFn: async ({ orderId }) => {

      setIsGenratingUrl(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/advertiser/order/payment`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ orderId: orderId }),
        }
      );

      if (!response.ok) {
        const text = await response.json();
        if (text?.error?.status === 401) {
          navigate(authPrefixedRoutes.LOGIN);
        }
        throw new Error(text.message || "Failed to generate payment url");
      }

      const data = await response.json();
      toast.success("Payment url generated successfully. Redirecting...");

      setTimeout(() => {
        window.open(data?.url, "_blank");
      }, 1000);

      setIsGenratingUrl(false);

      return data;
    },
    onError: (error) => {
      toast.error(
        error.message || "Something went wrong while generating payment url"
      );
    },
  });

  return {
    getPaymentUrl,
    isLoadingUrl: isLoading,
    isErrorUrl: isError,
    dataUrl: data,
    isGenratingUrl,
  };
};

export default useGetPaymentUrl;
