import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";

const useClinicList = (initialQueryParams) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [clinicList, setClinicList] = useState([]);
  const [clinicListMeta, setClinicListMeta] = useState({});
  const [clinicListError, setClinicListError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchClinicList = useQuery({
    queryKey: ["getAllcardHolder", initialQueryParams], // Include queryParams in the query key
    queryFn: async () => {
      // Modify queryFn to accept queryParams
      try {
        setIsLoading(true);
        let url = constructClinicListUrl(initialQueryParams);
        //console.log("API URL:", url); // Log the API URL
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        //console.log("hello");

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          const text1 = await response.text(); // Get response text for error handling
          //console.error("Error response:", text);
          setClinicListError(text1);
          setIsLoading(false);
          throw new Error("Failed to fetch data"); // Throw an error to trigger onError callback
        }

        const responseData = await response.json();
        //console.log("Response data:", responseData); // Log response data

        setClinicList(responseData.data); // Set clinic list from response data field
        setClinicListMeta(responseData.meta); // Set clinic list meta information
        setIsLoading(false);

        return responseData; // Return the fetched data
      } catch (error) {
        //console.error("Fetch error:", error); // Log fetch error
        setClinicListError(error);
        setIsLoading(false);
        throw error;
      }
    },
    onError: (error) => {
      //console.error("Query error:", error); // Log query error
      setClinicListError(error);
      setIsLoading(false);

      throw error;
    },
  });

  const fetchClinicListWithParams = (queryParams) => {
    //console.log("qp:",queryParams);
    const apiUrl = constructClinicListUrl(queryParams);
    //console.log("apiUrl:",apiUrl);
    fetchClinicList.refetch(apiUrl);
  };

  const constructClinicListUrl = (queryParams) => {
    //console.log("qp:",queryParams);
    //console.log("qps:",JSON.stringify(queryParams) );
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");
    //console.table("qss:",queryString);
    return `${process.env.REACT_APP_API_BASE_URL}/api/admin/payment-account-management/card-request/all?${queryString}`;
  };

  return {
    fetchClinicListWithParams,
    isLoading,
    isError: fetchClinicList.isError,
    clinicList,
    setClinicList,
    clinicListMeta,
    clinicListError,
  };
};

export default useClinicList;
