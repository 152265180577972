import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import authPrefixedRoutes from "../../../../routes/all-routes/authRoutes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useDeclineCard = () => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const [declineCardLoading, setDeclineCardLoading] = useState(false);
  const [declineCardError, setDeclineCardError] = useState(null);
  const [declineCardSuccess, setDeclineCardSuccess] = useState(false);

  const navigate = useNavigate();

  const {
    mutate: declineCard,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: "DeclineCard",
    mutationFn: async (clinicId) => {
      try {
        setDeclineCardLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/payment-account-management/card-holder-status-change-to-reject/${clinicId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            // body: JSON.stringify({}),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          const text = await response.json();
          if (text?.error?.status === 401) {
            navigate(authPrefixedRoutes.LOGIN);
          }
          throw new Error(data.message);
        }

        setDeclineCardSuccess(true);
        setDeclineCardLoading(false);
        toast.success("Card Request Rejected Successfully!");
        return data;
      } catch (error) {
        setDeclineCardError(error);
        setDeclineCardLoading(false);
        toast.error("Failed to reject card request!");
        throw error;
      }
    },
    onError: (error) => {
      setDeclineCardError(error);
      setDeclineCardLoading(false);
      throw error;
    },
  });

  return {
    declineCard,
    declineCardLoading,
    declineCardError,
    declineCardSuccess,
  };
};

export default useDeclineCard;
