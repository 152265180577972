// src/modules/admin/components/pages/Log.js
import React from "react";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

import { getFormattedDate } from "../../../../helpers/getFormattedDate";

import { Link } from "react-router-dom";
import { influencerPrefixedRoutes } from "../../../../routes/all-routes/influencerRoutes";

const fetchLogs = async ({ token }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/influencer/stripe-card/list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch card list");
    }

    const data = await response.json();


    return data;
  } catch (error) {
    console.error("Error fetching card list:", error);
    throw error;
  }
};

const CardList = (refetchKey) => {
  const token = useSelector((state) => state.auth.user.accessToken);

  const { data, isLoading } = useQuery({
    queryKey: ["cardListInfluencer", refetchKey],
    queryFn: () =>
      fetchLogs({
        token,
      }),
    keepPreviousData: true,
  });

  const columns = [
    {
      name: "Index",
      cell: (row, rowIndex) => rowIndex + 1,
      width: "80px",
    },
    {
      name: "Card ID",
      selector: (row) => (
        <Link
          className={`link-style ${
            row.status !== "active" ? "disabled-link" : ""
          }`}
          to={
            row.status === "active"
              ? `${influencerPrefixedRoutes.CARD_DETAILS}/${row._id}`
              : "#"
          }
          onClick={(e) => {
            if (row.status !== "active") e.preventDefault(); // Prevent navigation for inactive rows
          }}
        >
          {row.cardId || "Not Available"}
        </Link>
      ),
      sortable: true,
    },
    { name: "type", selector: (row) => row.type || "-", sortable: true },
    { name: "Status", selector: (row) => row.status || "-", sortable: true },

    {
      name: "Date & Time",
      selector: (row) => getFormattedDate(row.createdAt),
      sortable: true,
    },
  ];



  return (
    <>
      <div className=" my-4">
        <h4 className="mb-4 fw-bold text-title">All Card List</h4>

        {/* DataTable Component */}
        <div className="shadow rounded-2 mb-5">
          <DataTable
            columns={columns}
            data={data?.data}
            progressPending={isLoading}
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};

export default CardList;
