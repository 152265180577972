import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import BackButton from "../../../../helpers/backButton";
import Loader from "../../../common/components/loader/Loader";

const LogDetails = () => {
  const { id } = useParams(); // Get ID from the route parameters
  const token = useSelector((state) => state.auth.user.accessToken);

  const [logDetails, setLogDetails] = useState(null); // State to store log details
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    // Function to fetch log details based on ID
    const fetchLogDetails = async () => {
      try {
        setLoading(true); // Set loading state
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/log/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ); // Replace with your API endpoint
        setLogDetails(response.data); // Save API response to state
      } catch (err) {
        setError(err.message || "Failed to fetch log details"); // Save error message
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    if (id) fetchLogDetails(); // Fetch log details if ID exists
  }, [id]);

  if (loading) return <Loader />; // Display loading state
  if (error) return <p>Error: {error}</p>; // Display error message

  return (
    <div className="container py-4">
      <BackButton />
      <h2>Log Details</h2>
      <hr />
      {/* Render response object as JSON */}
      <pre
        style={{ background: "#f8f9fa", padding: "1rem", borderRadius: "5px" }}
      >
        {JSON.stringify(logDetails, null, 2)}
      </pre>
    </div>
  );
};

export default LogDetails;
