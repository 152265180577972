import { CircleLoader } from "react-spinners";
import useGetTransactionList from "../../../../../hooks/data-hooks/common/transaction/useGetTransactionList";
import StatsCard from "./StatsCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Stats = () => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  // Fetch transaction list with params
  const {
    transactionHistory,
    isLoadingTransactionList,
    fetchTransactionListWithParams,
  } = useGetTransactionList({});

  useEffect(() => {
    fetchTransactionListWithParams({});
  }, []);

  const getBallance = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/influencer/top-up/available-balance`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      setBalance(data.data[0].amount);
    } catch (error) {
      console.error("Error fetching balance:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBallance();
  }, []);


  if (isLoadingTransactionList || loading) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-4 bg-white w-100 h-100">
        <CircleLoader size={60} color="#df3459" />
      </div>
    );
  }

  return (
    <div className="d-flex flex-wrap  bg-white rounded-4 border h-100   ">
      <StatsCard
        title="Transactions"
        sales={transactionHistory?.totalTransaction}
      />

      <StatsCard title="Due" sales={transactionHistory?.totalDue} />
      <StatsCard
        title="Received"
        sales={transactionHistory?.totalReceivedAmount}
      />

      <StatsCard title="Usable Balance" sales={balance} />
    </div>
  );
};
export default Stats;
