// src/modules/influencer/components/cms/CatdTransactions.js
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { getFormattedDate } from "../../../../helpers/getFormattedDate";

const fetchData = async ({
  cardId,
  token,
  page,
  pageSize,
  filters,
  setTotalRows,
}) => {
  const { transactionType, status } = filters;

  const params = new URLSearchParams({
    cardId,
    pageSize,
    pageNumber: page,
    ...(transactionType && { transactionType }),
    ...(status && { status }),
  });

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/influencer/top-up/all?${params}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch transactions");
    }

    const data = await response.json();

    setTotalRows(data.meta.total_count);
    return data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

const CatdTransactions = ({ cardId }) => {
  const token = useSelector((state) => state.auth.user.accessToken);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [filters, setFilters] = useState({
    transactionType: "",
    status: "",
  });

  const { data, isLoading } = useQuery({
    queryKey: ["logs", cardId, token, currentPage, perPage, filters],
    queryFn: () =>
      fetchData({
        cardId,
        token,
        page: currentPage,
        pageSize: perPage,
        filters,
        setTotalRows,
      }),
    keepPreviousData: true,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev, [key]: value };
      return updatedFilters;
    });
    setCurrentPage(1); // Reset to first page on filter change
  };

  const columns = [
    {
      name: "Index",
      cell: (row, rowIndex) => (currentPage - 1) * perPage + rowIndex + 1,
      width: "80px",
    },
    {
      name: "Transaction ID",
      selector: (row) => row._id,
      sortable: true,
      width: "230px",
    },
    {
      name: "Type",
      selector: (row) => row.transactionType || "-",
      sortable: true,
    },
    { name: "Amount", selector: (row) => row.amount || "-", sortable: true },
    {
      name: "Balance Before",
      selector: (row) => row.balanceBefore || "-",
      sortable: true,
    },
    {
      name: "Balance After",
      selector: (row) => row.balanceAfter || "-",
      sortable: true,
    },
    { name: "Status", selector: (row) => row.status || "-", sortable: true },

    {
      name: "Date & Time",
      selector: (row) => getFormattedDate(row.createdAt),
      sortable: true,
      width: "180px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f4f4f4",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        padding: "8px",
        textTransform: "capitalize",
      },
    },
  };

  return (
    <div className="bg-white p-4 my-4 rounded-3 shadow">
      <div className="d-flex flex-wrap  justify-content-between ">
        <h5 className="mb-4 fw-semibold me-4">Card Transactions</h5>

        {/* Filter Section */}
        <div className="d-flex flex-wrap gap-3 mb-3 align-items-end">
          <div>
            <label className="form-label">Type</label>
            <select
              value={filters.transactionType}
              onChange={(e) =>
                handleFilterChange("transactionType", e.target.value)
              }
              className="form-select"
            >
              <option value="">All</option>
              <option value="top-up">Top Up</option>
              <option value="withdrawal">Withdrawal</option>
            </select>
          </div>
          <div>
            <label className="form-label">Status</label>
            <select
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
              <option value="failed">Failed</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
        </div>
      </div>

      {/* DataTable Component */}
      <div className="mt-3   ">
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={data?.data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          paginationDefaultPage={currentPage}
          onChangePage={(page) => {
            setCurrentPage(page);
          }}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPerPage(newPerPage);
            setCurrentPage(page);
          }}
          highlightOnHover
        />
      </div>
    </div>
  );
};

export default CatdTransactions;
