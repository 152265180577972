// src/modules/influencer/components/cms/CardDetails.js
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../../common/components/loader/Loader";
import logo from "../../../../assets/image/logo-white.png";
import {
  FaCalendarDay,
  FaCalendarWeek,
  FaCalendarAlt,
  FaLock,
  FaRegCopy,
} from "react-icons/fa";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import BackButton from "../../../../helpers/backButton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import CardTransactions from "./CardTransactions";

const CardDetails = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.auth.user.accessToken);
  const [isGeneratingKey, setIsGeneratingKey] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [nonce, setNonce] = useState(null);
  const [elements, setElements] = useState(null);
  const [ephemeralKeySecret, setEphemeralKeySecret] = useState(null);
  const theme = "dark"; // 'default' or 'dark'
  const [cardId, setCardId] = useState(null);
  const [spendingLimits, setSpendingLimits] = useState([]);
  const [cardDetails, setCardDetails] = useState(null);
  const [cardDetailsLoading, setCardDetailsLoading] = useState(true);
  const [isRevealed, setIsRevealed] = useState(false);
  const [countdown, setCountdown] = useState(0); // Countdown timer in seconds
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [password, setPassword] = useState(""); // State to track password input
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State to show password error

  const [showTopUpModal, setShowTopUpModal] = useState(false); // For Top-Up Modal
  const [topUpAmount, setTopUpAmount] = useState(""); // Top-up form amount
  const [isToppingUp, setIsToppingUp] = useState(false); // Loading spinner for top-up
  const [refetchKey, setRefetchKey] = useState(true);

  // handle password reveal
  const handleRevealClick = () => {
    setShowModal(true); // Show modal for password entry
  };

  // Countdown timer for password verification
  useEffect(() => {
    let timer;
    if (isRevealed && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (isRevealed && countdown === 0) {
      // Clear and unmount Stripe elements
      const cardNumberElement = elements?.getElement(
        "issuingCardNumberDisplay"
      );
      const cardExpiryElement = elements?.getElement(
        "issuingCardExpiryDisplay"
      );
      const cardCvcElement = elements?.getElement("issuingCardCvcDisplay");
      const cardCopyElement = elements?.getElement("issuingCardCopyButton");

      if (cardNumberElement) cardNumberElement.unmount();
      if (cardExpiryElement) cardExpiryElement.unmount();
      if (cardCvcElement) cardCvcElement.unmount();
      if (cardCopyElement) cardCopyElement.unmount();

      setIsRevealed(false); // Hide details
    }

    return () => clearInterval(timer);
  }, [isRevealed, countdown, elements]);

  // hiding, replacing, and re-showing icons upon copy click
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const hideAndShowSuccess = (iconElementId, successIconElementId) => {
    const el = document.getElementById(iconElementId);
    el.style.display = "none";
    const elSuccess = document.getElementById(successIconElementId);
    elSuccess.style.display = "block";
    timeout(2000).then(() => {
      elSuccess.style.display = "none";
      el.style.display = "block";
    });
  };

  // fetching card details
  useEffect(() => {
    setCardDetailsLoading(true);
    const getCardDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/influencer/stripe-card/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setCardId(data.data.card.cardId);
        setSpendingLimits(data.data.card.spending_limits);
        setCardDetails(data.data);

        return data;
      } catch (error) {
        console.error("Error fetching card details:", error);
        return null;
      } finally {
        setCardDetailsLoading(false);
      }
    };
    getCardDetails();
  }, [token, id, refetchKey]);

  // initializing stripe
  useEffect(() => {
    const initializeStripe = async () => {
      setIsInitializing(true);
      try {
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        const elements = stripe.elements();
        setElements(elements);

        const nonceResult = await stripe.createEphemeralKeyNonce({
          issuingCard: cardId,
        });
        setNonce(nonceResult.nonce);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      } finally {
        setIsInitializing(false);
      }
    };

    initializeStripe();
  }, [cardId]);

  // generating ephemeral key
  const genarateKey = async () => {
    setIsGeneratingKey(true);
    setShowModal(false);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/stripe-card/create-card`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ card_id: cardId, password }),
        }
      );
      const result = await response.json();
      if (response.ok) {
        toast.success("Authorization successful");
        setIsRevealed(true);
        setCountdown(60); // Start 60-second countdown
        setShowModal(false); // Close modal
        setPassword(""); // Reset password
        setErrorMessage(""); // Reset error
        setEphemeralKeySecret(result?.ephemeralKeySecret?.secret);
      }

      // setEphemeralKeySecret(result?.ephemeralKeySecret?.secret);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setIsGeneratingKey(false);
    }
  };

  // displaying card details
  useEffect(() => {
    if (nonce && ephemeralKeySecret && cardId) {
      const cardNumber = elements.create("issuingCardNumberDisplay", {
        issuingCard: cardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: {
          base: {
            fontSize: "20px",
            lineHeight: "20px",
            color: "#fff",
          },
        },
      });
      cardNumber.mount("#card-number");

      const cardCvc = elements.create("issuingCardCvcDisplay", {
        issuingCard: cardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: {
          base: {
            fontSize: "12px",
            lineHeight: "12px",
            color: "#fff",
          },
        },
      });

      cardCvc.mount("#card-cvc");
      const cardExpiry = elements.create("issuingCardExpiryDisplay", {
        issuingCard: cardId,
        nonce: nonce,
        ephemeralKeySecret: ephemeralKeySecret,
        style: {
          base: {
            fontSize: "12px",
            lineHeight: "12px",
            color: "#fff",
          },
        },
      });

      cardExpiry.mount("#card-expiry");

      const cardNumberCopy = elements.create("issuingCardCopyButton", {
        toCopy: "number",
        style: {
          base: {
            fontSize: "10px",
            lineHeight: "10px",
          },
        },
      });

      cardNumberCopy.mount("#card-number-copy");

      cardNumberCopy.on("click", () => {
        hideAndShowSuccess("card-number-copy", "card-number-copy-success");
      });
    }
  }, [nonce, ephemeralKeySecret, cardId]);

  // Helper function to get the limit for a specific interval
  const getLimitForInterval = (interval) => {
    const limit = spendingLimits.find((item) => item.interval === interval);
    return limit ? `$${limit.amount}` : "N/A";
  };

  // Format address
  const formattedAdress = (address) => {
    return `${address.line1}, ${address.line2}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`;
  };

  // Handle Top-Up Submission
  const handleTopUp = async () => {
    if (!topUpAmount || isNaN(topUpAmount) || Number(topUpAmount) <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }

    try {
      setIsToppingUp(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/influencer/top-up/request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ amount: topUpAmount, cardId: id }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "Top-up successful!");
        setTopUpAmount("");
      } else {
        // Extract error message from response
        const errorData = await response.json();
        toast.error(errorData.error || "An error occurred during top-up.");
      }
    } catch (error) {
      toast.error("An error occurred during top-up.");
      console.error(error);
    } finally {
      setIsToppingUp(false);
      setRefetchKey((prevKey) => !prevKey);
      setShowTopUpModal(false);
    }
  };

  // Copy to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  if (isInitializing || cardDetailsLoading || !cardId) return <Loader />;

  return (
    <div className="container ">
      {/* Top-Up Modal */}
      {showTopUpModal && (
        <div className="credit-modal-overlay">
          <div className="credit-modal">
            <h5 className="text-title mb-3">Top Up Card</h5>
            <input
              type="number"
              value={topUpAmount}
              onChange={(e) => setTopUpAmount(e.target.value)}
              placeholder="Enter amount"
              className="form-control mb-3"
              min="1"
            />
            <div className="credit-modal-actions">
              <button
                onClick={handleTopUp}
                className="btn btn-primary me-2"
                disabled={isToppingUp}
              >
                {isToppingUp ? "Processing..." : "Submit"}
              </button>
              <button
                onClick={() => setShowTopUpModal(false)}
                className="btn btn-secondary"
                disabled={isToppingUp}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal */}
      {showModal && (
        <div className="credit-modal-overlay">
          <div className="credit-modal">
            <h6 className="text-title mb-3 text-start">
              <FaLock /> Enter Password
            </h6>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="*********"
                className="form-control mb-2"
              />

              <span
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer position-absolute top-50 icon-end translate-middle-y"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {errorMessage && (
              <p className="text-danger small">{errorMessage}</p>
            )}
            <div className="credit-modal-actions">
              <button onClick={genarateKey} className="btn btn-primary me-2">
                Submit
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  setPassword("");
                  setErrorMessage("");
                }}
                className="btn btn-secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <BackButton />
      <div className="card-summary ">
        {/* Left Section: Summary Details */}
        <div className="summary-details">
          <div className="d-flex align-items-center gap-4 mb-4">
            <h3 className="mb-0">Summary</h3>
            <button
              onClick={() => setShowTopUpModal(true)}
              className="btn bg-primary-color text-white "
            >
              Top Up Card
            </button>
          </div>
          <ul className="list-unstyled summary-list">
            <li className="summary-item">
              <span className="summary-label">Card ID</span>
              <span className="summary-value text-break">
                {cardDetails.card.cardId || "N/A"}
                <FaRegCopy
                  className="copy-icon"
                  onClick={() => copyToClipboard(cardDetails.card.cardId)}
                  title="Copy to clipboard"
                />
              </span>
            </li>
            <li className="summary-item">
              <span className="summary-label">Cardholder Name</span>
              <span className="summary-value">
                {cardDetails.cardholder.name}
              </span>
            </li>
            <li className="summary-item">
              <span className="summary-label">Card Number</span>
              <span className="summary-value">
                {" "}
                **** **** **** {cardDetails.cardDetails.last4 || "N/A"}
              </span>
            </li>

            <li className="summary-item">
              <span className="summary-label">Card Status</span>
              <span
                className={`summary-value status ${cardDetails.card.status.toLowerCase()}`}
              >
                {cardDetails.card.status === "active" ? (
                  <FaCheckCircle className="status-icon" />
                ) : (
                  <FaTimesCircle className="status-icon" />
                )}
                {cardDetails.card.status}
              </span>
            </li>
            <li className="summary-item">
              <span className="summary-label">Balance</span>
              <span
                className={`summary-value status ${
                  cardDetails.balance > 0 ? "text-success" : "text-danger"
                } `}
              >
                {cardDetails.balance}{" "}
                {cardDetails?.card?.currency?.toUpperCase()}{" "}
              </span>
            </li>

            <li className="summary-item">
              <span className="summary-label">Expires at</span>
              <span className="summary-value">
                {`${cardDetails.cardDetails.exp_month} / ${cardDetails.cardDetails.exp_year}` ||
                  "N/A"}
              </span>
            </li>
            <li className="summary-item">
              <span className="summary-label">Card Type</span>
              <span className="summary-value type-badge">
                {cardDetails.card.type}
              </span>
            </li>

            <li className="summary-item">
              <span className="summary-label">Created at</span>
              <span className="summary-value">
                {new Date(cardDetails.card.createdAt).toLocaleString()}
              </span>
            </li>

            <li className="summary-item">
              <span className="summary-label">Billing Address</span>
              <span className="summary-value">
                {formattedAdress(cardDetails.cardholder) || "N/A"}
              </span>
            </li>
          </ul>
        </div>

        {/* Right Section: Card and Balance */}
        <div className={`card-display`}>
          {cardDetails?.card?.type === "virtual" && (
            <div className="credit-card__actions">
              <span
                onClick={handleRevealClick}
                className={`credit-card__reveal-button ${
                  isRevealed && !isGeneratingKey ? "hide" : ""
                } `}
              >
                {isGeneratingKey ? (
                  "Please wait..."
                ) : (
                  <>
                    {isRevealed ? null : "Reveal"}
                    {isRevealed ? null : <FaEyeSlash />}
                  </>
                )}
              </span>
              {isRevealed && (
                <span className="credit-card__countdown">{countdown}s</span>
              )}
            </div>
          )}
          {isRevealed ? (
            <>
              <div
                className={`credit-card credit-card--${theme} card-container `}
              >
                <div className="credit-card__background">
                  <img
                    src={logo}
                    alt="Card Logo"
                    className="credit-card__logo"
                  />
                </div>
                <div className="credit-card__content">
                  <div
                    id="card-number"
                    className={`credit-card__number ${
                      !isRevealed ? "d-none" : ""
                    }`}
                  ></div>
                  <div
                    id="card-number-copy"
                    className={!isRevealed ? "d-none" : ""}
                  ></div>
                  <div
                    id="card-number-copy-success"
                    className={!isRevealed ? "d-none" : ""}
                  ></div>
                  <div className="credit-card__details">
                    <div className="credit-card__holder">
                      <span>Cardholder</span>
                      <p>{cardDetails?.cardholder?.name}</p>
                    </div>
                  </div>
                  <div className="credit-card__additional-details">
                    <div>
                      <span>Expires</span>
                      <div id="card-expiry"></div>
                    </div>
                    <div className="d-flex align-items-start">
                      <div>
                        <span>CVC</span>
                        <div id="card-cvc"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className={`credit-card credit-card--${theme} card-container `}
              >
                <div className="credit-card__background">
                  <img
                    src={logo}
                    alt="Card Logo"
                    className="credit-card__logo"
                  />
                </div>
                <div className="credit-card__content">
                  <div className="credit-card__number">
                    **** **** **** {cardDetails.cardDetails.last4 || "N/A"}
                  </div>

                  <div className="credit-card__details w-100">
                    <div className="credit-card__holder">
                      <span>Cardholder</span>
                      <p>{cardDetails?.cardholder?.name}</p>
                    </div>
                  </div>
                  <div className="credit-card__additional-details">
                    <div>
                      <span>Expires</span>
                      <p>
                        {cardDetails?.cardDetails?.exp_month &&
                        cardDetails?.cardDetails?.exp_year
                          ? `${
                              cardDetails.cardDetails.exp_month
                            }/${cardDetails.cardDetails.exp_year
                              .toString()
                              .slice(2)}`
                          : "N/A"}
                      </p>
                    </div>
                    <div className="d-flex align-items-start">
                      <div>
                        <span>CVC</span>
                        <p> *** </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="d-flex flex-wrap gap-3 mt-3">
        <section className="card-limit flex-1">
          <h5 className=" mb-4 fw-semibold ">Spending Limits</h5>
          <div className="row g-3">
            {/* Daily Limit */}
            <div className="col-md-4">
              <div className="card text-center limit-card">
                <div className="card-body">
                  <FaCalendarDay size={40} className="mb-3 text-primary" />
                  <h5 className="card-title">Daily</h5>
                  <p className="card-text limit-amount">
                    {getLimitForInterval("daily")}
                  </p>
                </div>
              </div>
            </div>
            {/* Weekly Limit */}
            <div className="col-md-4">
              <div className="card text-center limit-card">
                <div className="card-body">
                  <FaCalendarWeek size={40} className="mb-3 text-success" />
                  <h5 className="card-title">Weekly</h5>
                  <p className="card-text limit-amount">
                    {getLimitForInterval("weekly")}
                  </p>
                </div>
              </div>
            </div>
            {/* Monthly Limit */}
            <div className="col-md-4">
              <div className="card text-center limit-card">
                <div className="card-body">
                  <FaCalendarAlt size={40} className="mb-3 text-warning" />
                  <h5 className="card-title">Monthly</h5>
                  <p className="card-text limit-amount">
                    {getLimitForInterval("monthly")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <CardTransactions cardId={cardId} />
    </div>
  );
};
export default CardDetails;

/* <div className=" mt-3 bg-white p-3 rounded shadow">
  <h5 className="mb-3 fw-semibold "> Top Up</h5>
  {!showTopUpForm && (
    <button
      onClick={() => setShowTopUpForm(true)}
      className="btn bg-primary-color text-white "
    >
      Top Up Card
    </button>
  )}
  {showTopUpForm && <TopUpForm setshowTopUpForm={setShowTopUpForm} />}
</div>; */

// Copy to clipboard
// const copyToClipboard = (text) => {
//   navigator.clipboard.writeText(text);
//   alert("Copied to clipboard!");
// };

/* Transaction History */

/* <section className="transaction-history mt-4">
        <h3>Transaction History</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Merchant</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2024-11-20</td>
              <td>Amazon</td>
              <td>$150.00</td>
              <td>Completed</td>
            </tr>
            <tr>
              <td>2024-11-18</td>
              <td>Starbucks</td>
              <td>$25.00</td>
              <td>Pending</td>
            </tr>
          </tbody>
        </table>
      </section> */

/* Card Actions */

/* <section className="card-actions mt-4">
        <h3>Card Actions</h3>
        <button className="btn btn-primary me-2">Freeze Card</button>
        <button className="btn btn-secondary">Set Spending Limit</button>
      </section> */

/* Security Settings */

/* <section className="security-settings mt-4">
        <h3>Security Settings</h3>
        <form>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="enable-online-transactions"
            />
            <label
              className="form-check-label"
              htmlFor="enable-online-transactions"
            >
              Enable Online Transactions
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="alert-for-suspicious-activity"
            />
            <label
              className="form-check-label"
              htmlFor="alert-for-suspicious-activity"
            >
              Alert for Suspicious Activity
            </label>
          </div>
        </form>
      </section> */

/* Billing Information */

/* <section className="billing-info mt-4">
        <h3>Billing Information</h3>
        <p>1234 Elm Street, Apt 56, Springfield, IL, USA</p>
      </section> */

/* Help and Support */

/* <section className="help-support mt-4">
        <h3>Help & Support</h3>
        <p>
          Need help? <a href="/support">Contact Support</a> or check out our{" "}
          <a href="/faq">FAQs</a>.
        </p>
      </section> */
