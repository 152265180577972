import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import useCreateCategory from "../../../../hooks/data-hooks/advertiser/feedback/useCreateCategory";
import BackButton from "../../../../helpers/backButton";
import { useParams } from "react-router";

const CreateFeedback = () => {
  const {
    updateContent,
    isLoading,
    isError,
    error,
    submitSuccess,
    isSubmitting,
  } = useCreateCategory();

  const { id } = useParams(); // Get the dynamic id from the URL

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    // Show error toast if isError is true
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  useEffect(() => {
    // Show success toast if submitSuccess is true
    if (submitSuccess) {
      toast.success("Feedback Added Successfully");
    }
  }, [submitSuccess]);

  const onSubmit = async (data) => {

    // Prevent form submission if there is an ongoing login process
    if (isSubmitting) return;

    try {
      const influencer = id; // Replace with actual ID or fetch dynamically

      const formData = { ...data, influencer };
      // Call the custom hook for API call
      await updateContent(formData, id);
    } catch (error) {
      // Error handling is done in useEffect
    }
  };

  // console.log();
  return (
    <div className="container">
      <div className=" col-lg-6">
        <div className="cms-card  ">
          <BackButton />
          <form className="cms-form   mb-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="fs-24 fw-bold ">Add Your Feedback </div>

            <div className="mt-5">
              <div className="form-item">
                <label className="">Feedback</label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className=""
                    placeholder="Write your Feedback"
                    {...register("content", {
                      required: "Feedback is required",
                    })}
                  />
                  {errors.content && (
                    <p className="error-message">{errors.content.message}</p>
                  )}
                </div>
              </div>
              <div className="form-item">
                <label className="">Ratings</label>
                <div className="input-wrapper">
                  <input
                    type="number"
                    className=""
                    placeholder="Enter Your Ratings"
                    {...register("rating", {
                      required: "Ratings are required",
                      min: {
                        value: 1,
                        message: "ratings minimum 1",
                      },
                      max: {
                        value: 5,
                        message: "ratings maximum 5",
                      },
                      // pattern: {
                      //   value: /^[0-5]+$/,
                      //   message: "Validation in days must be a number",
                      // },
                    })}
                  />
                  {errors.rating && (
                    <p className="error-message">{errors.rating.message}</p>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="w-100 button-primary h-64px">
              {isSubmitting ? "Adding..." : "Add Feedback & Ratings"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateFeedback;
